import { TagDTO } from '~src/generated/api';
import { Card } from './Card';
import cx from 'classnames';

export const TagCard = ({
  tag,
  onClick,
}: {
  tag: TagDTO;
  onClick: () => void | Promise<void>;
}) => {
  return (
    <Card
      className={cx('p-4 bg-accent-300 text-accent-900', {
        'hover:bg-accent-500': onClick !== undefined,
      })}
      onClick={onClick}
    >
      <h2>{tag.name}</h2>
    </Card>
  );
};
