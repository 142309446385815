import { mdiClose, mdiContentSave, mdiDelete, mdiPencil } from '@mdi/js';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LocationCard } from '~src/components/cards/LocationCard';
import { RoleCard } from '~src/components/cards/RoleCard';
import {
  Button,
  ButtonColor,
  ButtonType,
} from '~src/components/controls/Button';
import { Chip } from '~src/components/icons/Chip';
import { Loading } from '~src/components/icons/Loading';
import { RoleAccessIndicator } from '~src/components/icons/RoleAccessIndicator';
import { DeleteDialog } from '~src/components/sections/DeleteDialog';
import { Header, HeaderType } from '~src/components/sections/Header';
import { ItemList } from '~src/components/sections/ItemList';
import { NotFoundMessage } from '~src/components/sections/NotFoundMessage';
import { routes } from '~src/routes';
import {
  useDeleteRole,
  useRoleById,
  useRoleItems,
  useRoleLocations,
  useRoleRoles,
  useRoleTags,
  useUpdateRole,
} from '~src/state/roles';
import { showToast } from '~src/utils/toast';
import { EditRoleData, EditRoleScreen } from './EditRoleScreen';

export const RoleScreen = () => {
  const urlParams = useParams();
  const navigate = useNavigate();

  const roleId = Number(urlParams.roleId);
  if (roleId === undefined || isNaN(roleId)) {
    return <NotFoundMessage />;
  }

  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [isEditVisible, setEditVisible] = useState<boolean>(false);

  const { error, isLoading, data: role } = useRoleById(roleId);
  const deleteRole = useDeleteRole();
  const updateRole = useUpdateRole();

  const roleItems = useRoleItems(role);
  const roleLocations = useRoleLocations(role);
  const roleTags = useRoleTags(role);
  const roleRoles = useRoleRoles(role);

  if (error) {
    return <NotFoundMessage />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isEditVisible) {
    return (
      <EditRoleScreen
        title='Edit role'
        roleId={role?.id}
        data={{
          name: role?.name,
          isAdmin: role?.admin,
          roleItems: roleItems.data ?? [],
          roleLocations: roleLocations.data ?? [],
          roleTags: roleTags.data ?? [],
          roleRoles: roleRoles.data ?? [],
        }}
        onSubmit={(newRole: EditRoleData) => {
          updateRole
            .mutateAsync({
              roleId: role?.id,
              data: {
                name: newRole.name,
                admin: newRole.isAdmin,
                managedItemIds: newRole.roleItems.flatMap(
                  (item) => item.id ?? []
                ),
                managedLocationIds: newRole.roleLocations.flatMap(
                  (loc) => loc.id ?? []
                ),
                managedTagIds: newRole.roleTags.flatMap((tag) => tag.id ?? []),
                managedRoleIds: newRole.roleRoles.flatMap(
                  (manRole) => manRole.id ?? []
                ),
              },
            })
            .then(() => {
              showToast.success('Role updated successfully');
              setEditVisible(false);
            })
            .catch(() => {
              showToast.error('Could not update role');
            });
        }}
        onCancel={() => setEditVisible(false)}
      />
    );
  }

  return (
    <>
      <DeleteDialog
        title='Delete role'
        description='This will delete the role!'
        text='Are you sure you want to delete this role?'
        visible={isDeleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDelete={() => {
          deleteRole
            .mutateAsync(role?.id)
            .then(() => {
              showToast.success('Role deleted successfully');
              navigate(routes.manage);
            })
            .catch(() => {
              showToast.error('Could not delete role');
            });
        }}
      />

      <div className='my-4'>
        <Header text={role?.name ?? 'Role'} type={HeaderType.SCREEN} />
        <RoleAccessIndicator isUnlimitedAccess={role?.admin} className='my-4' />

        <section className='flex flex-wrap flex-row gap-x-4 gap-y-2'>
          <h2 className='sr-only'>Actions</h2>
          <Button
            icon={mdiPencil}
            color={ButtonColor.WHITE}
            type={ButtonType.DEFAULT}
            onClick={() => setEditVisible(true)}
          >
            Edit
          </Button>
          <Button
            icon={mdiDelete}
            color={ButtonColor.ERROR}
            type={ButtonType.DEFAULT}
            onClick={() => setDeleteOpen(true)}
          >
            Delete
          </Button>
        </section>

        <section className='my-8'>
          <Header
            text='Managed items'
            type={HeaderType.SECTION}
            className='flex-grow'
          />
          {roleItems.data === undefined || roleItems.data.length === 0 ? (
            <p className='text-gray-700 my-2'>No items</p>
          ) : (
            <div className='my-4'>
              <ItemList items={roleItems.data} />
            </div>
          )}
        </section>

        <section className='my-8'>
          <Header text='Managed locations' type={HeaderType.SECTION} />
          {(roleLocations.data?.length ?? 0) === 0 ? (
            <p className='text-gray-700 my-2'>No locations</p>
          ) : (
            <div className='rounded-lg bg-white shadow-soft overflow-clip my-4'>
              {roleLocations.data?.map((location) => (
                <LocationCard
                  key={location.id}
                  location={location}
                  onClick={() => {
                    navigate(routes.locationBySlug(location.slug));
                  }}
                />
              ))}
            </div>
          )}
        </section>

        <section className='my-8'>
          <Header text='Managed tags' type={HeaderType.SECTION} />
          {roleTags.data?.length === 0 ? (
            <p className='text-gray-700 my-2'>No tags</p>
          ) : (
            <div className='flex flex-wrap gap-4 my-2'>
              {roleTags.data?.map((tag) => (
                <Chip
                  key={tag.id}
                  text={tag.name}
                  onClick={() => {
                    navigate(routes.tagById(tag.id));
                  }}
                />
              ))}
            </div>
          )}
        </section>

        <section className='my-8'>
          <Header text='Managed roles' type={HeaderType.SECTION} />
          {(roleRoles.data?.length ?? 0) === 0 ? (
            <p className='text-gray-700 my-2'>No roles</p>
          ) : (
            <div className='my-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
              {roleRoles.data?.map((role) => (
                <RoleCard
                  key={role.id}
                  role={role}
                  onClick={() => navigate(routes.roleById(role.id))}
                />
              ))}
            </div>
          )}
        </section>

        {isEditVisible && (
          <section className='my-8'>
            <Button
              color={ButtonColor.PRIMARY_DARK}
              type={ButtonType.DEFAULT}
              icon={mdiContentSave}
              className='mr-2'
            >
              Save
            </Button>
            <Button
              color={ButtonColor.WHITE}
              type={ButtonType.DEFAULT}
              icon={mdiClose}
              onClick={() => setEditVisible(false)}
            >
              Cancel
            </Button>
          </section>
        )}
      </div>
    </>
  );
};
