import { mdiAt, mdiCardAccountDetailsOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { UserDTO } from '~src/generated/api';
import { Card } from './Card';

export const UserCard = ({
  user,
  onClick,
}: {
  user: UserDTO;
  onClick?: () => void | Promise<void>;
}) => {
  return (
    <Card className='p-4 select-none' onClick={onClick}>
      <p className='font-semibold'>
        {user.firstName} {user.lastName}
      </p>
      <p>
        <Icon
          path={mdiCardAccountDetailsOutline}
          size='1em'
          className='inline-block mr-2 align-sub text-gray-700'
        />
        {user.username}
      </p>
      <p>
        <Icon
          path={mdiAt}
          size='1em'
          className='inline-block mr-2 align-sub text-gray-700'
        />
        {user.email}
      </p>
    </Card>
  );
};
