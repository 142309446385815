import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import {
  TagControllerService,
  TagCreateDTO,
  TagDTO,
  TagUpdateDTO,
} from '~src/generated/api';
import { useEditable } from './editable';
import { useQuerySettings } from './querySettings';
import { useUserRoles } from './roles';

export const tagKeys = {
  all: () => ['tags'] as const,

  list: () => [...tagKeys.all(), 'list'] as const,
  allList: () => [...tagKeys.list(), 'all'] as const,

  detail: () => [...tagKeys.all(), 'detail'] as const,
  byId: (id?: number) => [...tagKeys.detail(), id] as const,

  managed: () => [...tagKeys.all(), 'managed'] as const,
};

export const useTagById = (id?: number) => {
  const querySettings = useQuerySettings();

  return useQuery(
    tagKeys.byId(id),
    async () => {
      return await TagControllerService.findTagById(id as number);
    },
    {
      ...querySettings,
      enabled: querySettings.enabled && id !== undefined && !isNaN(id),
    }
  );
};

export const useAllTags = (enabled = true) => {
  const querySettings = useQuerySettings();

  return useQuery(
    tagKeys.allList(),
    async () => {
      return await TagControllerService.allTags();
    },
    { ...querySettings, enabled: querySettings.enabled && enabled }
  );
};

const fetchTagById = (id: number) => TagControllerService.findTagById(id);

export const useTagList = (tagIds: number[], enabled: boolean) => {
  const querySettings = useQuerySettings();
  return useQueries(
    tagIds.map((tagId) => ({
      queryKey: tagKeys.byId(tagId),
      queryFn: () => fetchTagById(tagId),
      ...querySettings,
      enabled: querySettings.enabled && enabled,
    }))
  );
};

export const useManagedTags = (enabled = true) => {
  const roles = useUserRoles();

  return useTagList(
    [...new Set(roles?.flatMap((role) => role.data?.managedTagIds ?? []))],
    enabled
  );
};

export const useEditableTags = useEditable(useAllTags, useManagedTags);

export const useCreateTag = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  return useMutation(
    async (tag: TagCreateDTO) => {
      return await TagControllerService.saveTag(tag);
    },
    {
      ...querySettings,
      onSuccess: async () => {
        await queryClient.invalidateQueries(tagKeys.all());
      },
    }
  );
};

export const useUpdateTag = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  return useMutation(
    async ({ tagId, tag }: { tagId: number; tag: TagUpdateDTO }) => {
      return await TagControllerService.updateTag(tagId, tag);
    },
    {
      ...querySettings,
      onSuccess: async (tag: TagDTO) => {
        await queryClient.invalidateQueries(tagKeys.all());
        queryClient.setQueryData(tagKeys.byId(tag.id), tag);
      },
    }
  );
};

export const useDeleteTag = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  return useMutation(
    async (tagId?: number) => {
      if (tagId === undefined) return;
      await TagControllerService.deleteTag(tagId);
    },
    {
      ...querySettings,
      onSuccess: async () => {
        await queryClient.invalidateQueries(tagKeys.all());
      },
    }
  );
};
