import { Provider } from 'jotai';
import { render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { OpenAPI } from 'src/generated/api';
import { App } from './App';
import './index.css';

if (process.env.API_URL) {
  OpenAPI.BASE = process.env.API_URL;
}

const queryClient = new QueryClient();

render(
  <BrowserRouter>
    <Provider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
