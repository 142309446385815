import { useNavigate } from 'react-router-dom';
import { TagDTO } from '~src/generated/api';
import { routes } from '~src/routes';
import { useCreateTag } from '~src/state/tags';
import { showToast } from '~src/utils/toast';
import { EditTagData, EditTagScreen } from './EditTagScreen';

export const NewTagScreen = () => {
  const navigate = useNavigate();
  const createTag = useCreateTag();

  return (
    <EditTagScreen
      title='New tag'
      onSubmit={(newTag: EditTagData) => {
        createTag
          .mutateAsync(newTag)
          .then((tag?: TagDTO) => {
            showToast.success('Tag created successfully');
            navigate(
              tag !== undefined ? routes.tagById(tag.id) : routes.manage
            );
          })
          .catch(() => {
            showToast.error('Could not create tag');
          });
      }}
      onCancel={() => navigate(-1)}
    />
  );
};
