import { useAtom, useAtomValue } from 'jotai';
import { RESET } from 'jotai/utils';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NotFoundMessage } from 'src/components/sections/NotFoundMessage';
import { routes } from 'src/routes';
import { BaseScreen } from 'src/screens/BaseScreen';
import { LoginScreen } from 'src/screens/login/LoginScreen';
import { OverviewScreen } from 'src/screens/overview/OverviewScreen';
import { authTokenAtom, refreshTokenAtom } from 'src/state/auth';
import { ItemScreen } from '~src/screens/browse/item/ItemScreen';
import { LocationScreen } from '~src/screens/browse/location/LocationScreen';
import { BrowseScreen } from './screens/browse/BrowseScreen';
import { TagScreen } from './screens/browse/tag/TagScreen';
import { RegisterScreen } from './screens/login/RegisterScreen';
import { LoggingInScreen } from './screens/login/LoggingInScreen';
import { ManageScreen } from './screens/manage/ManageScreen';
import { MANAGE_TABS } from './screens/manage/manageTabs';
import { RoleScreen } from './screens/manage/role/RoleScreen';
import { UserScreen } from './screens/manage/user/UserScreen';
import { useIsAdmin } from './state/user';
import { OAuthScreen } from './screens/login/OAuthScreen';
import { useUserRoles } from './state/roles';

export const App = () => {
  const refreshToken = useAtomValue(refreshTokenAtom);
  const [authToken, setAuthToken] = useAtom(authTokenAtom);

  useEffect(() => {
    if (refreshToken === undefined || authToken !== undefined) {
      return;
    }
    void setAuthToken(RESET);
  }, [refreshToken, authToken]);

  const isAdmin = useIsAdmin();
  const userRoles = useUserRoles();

  const notFoundRoute = <Route path='*' element={<NotFoundMessage />} />;

  const loggedOutRoutes = (
    <Route element={<BaseScreen showTopBar={false} />}>
      <Route path={routes.main} element={<LoginScreen />} />
      <Route path={routes.register} element={<RegisterScreen />} />
      {notFoundRoute}
    </Route>
  );

  const loggedInRoutes = (
    <Route element={<BaseScreen showTopBar />}>
      <Route path={routes.main} element={<OverviewScreen />} />

      <Route path={routes.browse} element={<BrowseScreen />} />
      <Route
        path={`${routes.location}/:locationSlug`}
        element={<LocationScreen />}
      />
      <Route path={`${routes.item}/:itemSlug`} element={<ItemScreen />} />
      <Route path={`${routes.tag}/:tagId`} element={<TagScreen />} />

      {userRoles.length > 0 && (
        <>
          <Route path={routes.manage}>
            <Route
              index
              element={
                <Navigate to={`${routes.manage}/${MANAGE_TABS[0].id}`} />
              }
            />
            {MANAGE_TABS.map(
              (tab) =>
                (isAdmin.data === true || !tab.adminOnly) && (
                  <Route key={tab.id} path={tab.id}>
                    <Route index element={<ManageScreen tab={tab.id} />} />
                    {tab.creationScreen && (
                      <Route path='new' element={tab.creationScreen} />
                    )}
                  </Route>
                )
            )}
          </Route>
          <Route path={`${routes.role}/:roleId`} element={<RoleScreen />} />
          <Route path={`${routes.user}/:userId`} element={<UserScreen />} />
        </>
      )}

      {notFoundRoute}
    </Route>
  );

  let usedRoutes;
  if (refreshToken === null) {
    usedRoutes = loggedOutRoutes;
  } else if (
    authToken === null ||
    isAdmin.isLoading ||
    isAdmin.data === undefined
  ) {
    usedRoutes = <Route path='*' element={<LoggingInScreen />} />;
  } else {
    usedRoutes = loggedInRoutes;
  }

  return (
    <Routes>
      <Route path={`${routes.oauth}/:oauthType`} element={<OAuthScreen />} />
      {usedRoutes}
    </Routes>
  );
};
