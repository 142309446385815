/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReservationUpdateDTO = {
    startTime?: string;
    endTime?: string;
    state?: ReservationUpdateDTO.state;
    userId?: number;
    itemId?: number;
};

export namespace ReservationUpdateDTO {

    export enum state {
        CREATED = 'CREATED',
        CONFIRMED = 'CONFIRMED',
        DENIED = 'DENIED',
        CANCELLED = 'CANCELLED',
        COMPLETED = 'COMPLETED',
    }


}
