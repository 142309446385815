import { useEffect, useRef } from 'react';

/**
 * Small utility hook that returns information about whether
 * the component is mounted or not, this helps reduce memory leaks
 */
export const useMounted = (): React.MutableRefObject<boolean> => {
  const mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [mounted.current]);
  return mounted;
};
