import { useAtomValue } from 'jotai';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  ItemControllerService,
  ItemDTO,
  LocationControllerService,
  LocationDTO,
  ReservationControllerService,
  ReservationCreateDTO,
  ReservationDTO,
} from 'src/generated/api';
import { currentUserIdAtom } from './auth';
import { useQuerySettings } from './querySettings';

export type ReservationDetail = {
  reservation: ReservationDTO;
  item: ItemDTO;
  locations: LocationDTO[];
};

export const reservationKeys = {
  all: () => ['reservations'] as const,

  lists: () => [...reservationKeys.all(), 'list'] as const,

  upcoming: () => [...reservationKeys.lists(), 'upcoming'] as const,
  unconfirmed: () => [...reservationKeys.lists(), 'unconfirmed'] as const,

  forItem: (itemId?: number) =>
    [...reservationKeys.lists(), 'item', itemId] as const,
};

const reservationDtoToDetail = async (reservation: ReservationDTO) => {
  const item =
    reservation.itemId !== undefined
      ? await ItemControllerService.findItemById(reservation.itemId)
      : undefined;
  const locations =
    item?.locationId !== undefined
      ? (
          await LocationControllerService.allLocationParents(item.locationId)
        ).reverse()
      : [];
  return { reservation, item, locations } as ReservationDetail;
};

export const useUpcomingReservations = () => {
  const currentUserId = useAtomValue(currentUserIdAtom);
  const querySettings = useQuerySettings();

  const getUpcomingReservations = async () => {
    if (currentUserId === undefined) throw Error('No user ID specified');

    const reservations =
      await ReservationControllerService.upcomingUserReservations(
        currentUserId
      );

    return Promise.all(reservations.map(reservationDtoToDetail));
  };

  return useQuery<ReservationDetail[], Error>(
    reservationKeys.upcoming(),
    getUpcomingReservations,
    {
      ...querySettings,
      enabled: querySettings.enabled && currentUserId !== undefined,
    }
  );
};

export const useUnconfirmedReservations = () => {
  const currentUserId = useAtomValue(currentUserIdAtom);
  const querySettings = useQuerySettings();

  const getUnconfirmedReservations = async () => {
    if (currentUserId === undefined) throw Error('No user ID specified');

    const reservations =
      await ReservationControllerService.unconfirmedReservationsForUser(
        currentUserId
      );

    return Promise.all(reservations.map(reservationDtoToDetail));
  };

  return useQuery<ReservationDetail[], Error>(
    reservationKeys.unconfirmed(),
    getUnconfirmedReservations,
    {
      ...querySettings,
      enabled: querySettings.enabled && currentUserId !== undefined,
    }
  );
};

export const useUpcomingItemReservations = (itemId?: number) => {
  const querySettings = useQuerySettings();
  return useQuery<ReservationDetail[], Error>(
    reservationKeys.forItem(itemId),
    async () => {
      if (itemId === undefined) return [];
      const reservations =
        await ReservationControllerService.upcomingItemReservations(itemId);
      return Promise.all(reservations.map(reservationDtoToDetail));
    },
    {
      ...querySettings,
      enabled: querySettings.enabled && itemId !== undefined,
    }
  );
};

const addReservationToData =
  (newReservation: ReservationDetail) => (oldData?: ReservationDetail[]) =>
    [...(oldData ?? []), newReservation];

export const useCreateReservation = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  return useMutation(
    async (res: ReservationCreateDTO) => {
      return await ReservationControllerService.saveReservation(res);
    },
    {
      ...querySettings,
      onSuccess: async (data: ReservationDTO) => {
        const newReservation = await reservationDtoToDetail(data);
        queryClient.setQueryData(
          reservationKeys.forItem(data.itemId),
          addReservationToData(newReservation)
        );
        queryClient.setQueryData(
          reservationKeys.upcoming(),
          addReservationToData(newReservation)
        );
      },
    }
  );
};

export const useReservationActions = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  const changeState =
    (state: ReservationDTO.state) => async (reservation: ReservationDTO) => {
      if (reservation.id === undefined) {
        throw new Error('Reservation ID undefined');
      }
      return await ReservationControllerService.updateReservation(
        reservation.id,
        { ...reservation, state }
      );
    };

  const mutationSettings = {
    ...querySettings,
    onSuccess: async () => {
      await queryClient.invalidateQueries(reservationKeys.lists());
    },
  };

  const confirm = useMutation(
    changeState(ReservationDTO.state.CONFIRMED),
    mutationSettings
  );
  const cancel = useMutation(
    changeState(ReservationDTO.state.CANCELLED),
    mutationSettings
  );
  const deny = useMutation(
    changeState(ReservationDTO.state.DENIED),
    mutationSettings
  );
  const _delete = useMutation(async (reservationId: number) => {
    await ReservationControllerService.deleteReservation(reservationId);
  }, mutationSettings);

  return { confirm, cancel, deny, delete: _delete };
};
