import { ItemIndicator } from 'src/components/icons/ItemIndicator';
import { ItemDTO } from 'src/generated/api';
import { ClassNameProps } from '~src/utils/ClassNameProps';
import { Card } from './Card';

export interface ItemCardProps extends ClassNameProps {
  item: ItemDTO;
  imageSrc?: string;
  onClick?: () => void;
}

export const ItemCard = (props: ItemCardProps) => {
  // TODO replace with ItemDTO access once API is stabilised
  const availability = 100;

  return (
    <Card className={props.className} onClick={props.onClick}>
      <img
        className='w-full h-48 object-cover bg-gray-500 border border-gray-500'
        src={props.imageSrc}
        alt={props.item.name}
      />
      <div className='px-4 py-5'>
        <h1 className='font-display font-semibold mb-2'>{props.item.name}</h1>
        <ItemIndicator availability={availability} />
      </div>
    </Card>
  );
};
