import { Dialog, Transition } from '@headlessui/react';
import { mdiCheck, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Fragment, useState } from 'react';
import { Button, ButtonColor, ButtonType } from '../controls/Button';

export interface PickDialogProps<Type> {
  title: string;
  description: string;
  options: { name: string; value: Type }[];
  visible: boolean;
  onClose: () => void | Promise<void>;
  onPick: (option?: Type) => void | Promise<void>;
}

export const PickDialog = <Type,>(props: PickDialogProps<Type>) => {
  const [picked, setPicked] = useState<Type | undefined>();

  return (
    <Transition as={Fragment} show={props.visible}>
      <Dialog as='div' onClose={props.onClose}>
        <Transition.Child
          enter='ease-out duration-200'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-900 opacity-50' />
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-200'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='bg-gray-100 max-w-md m-auto p-6 rounded-lg shadow-mini'>
                <div className='flex flex-row items-center mb-4'>
                  <Dialog.Title className='text-2xl font-display font-semibold flex-grow'>
                    {props.title}
                  </Dialog.Title>
                  <div onClick={props.onClose}>
                    <Icon path={mdiClose} className='w-6 h-6 cursor-pointer' />
                  </div>
                </div>
                <Dialog.Description>{props.description}</Dialog.Description>
                <div className='pb-2'>
                  <label>
                    <select
                      className='form-input border-gray-300'
                      onChange={(event) => {
                        const value = Number(event.target.value);
                        if (!isNaN(value)) {
                          setPicked(props.options[value].value);
                        }
                      }}
                    >
                      <option key='none' value='none'>
                        None
                      </option>
                      {props.options.map((opt, index) => (
                        <option key={opt.name} value={index}>
                          {opt.name}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className='flex flex-row gap-4'>
                  <Button
                    color={ButtonColor.PRIMARY_LIGHT}
                    type={ButtonType.DEFAULT}
                    icon={mdiCheck}
                    onClick={async () => {
                      await props.onPick(picked);
                      void props.onClose();
                    }}
                    className='flex-grow'
                  >
                    Pick
                  </Button>
                  <Button
                    color={ButtonColor.WHITE}
                    type={ButtonType.DEFAULT}
                    icon={mdiClose}
                    onClick={props.onClose}
                    className='flex-grow'
                  >
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
