import { mdiAccountPlus, mdiLoginVariant } from '@mdi/js';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Card } from 'src/components/cards/Card';
import {
  Button,
  ButtonColor,
  ButtonType,
} from 'src/components/controls/Button';
import { AuthError, useAuthActions } from 'src/state/auth';
import { showToast } from 'src/utils/toast';
import { routes } from '~src/routes';

const welcome = new URL(
  '~/src/assets/undraw_welcome.svg',
  import.meta.url
).toString();

type LoginDetails = {
  username: string;
  password: string;
};

export const LoginScreen = () => {
  const { login } = useAuthActions();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginDetails>();

  const navigate = useNavigate();

  const loginOauth = () => {
    if (!process.env.API_URL) return;
    window.location.href = `${process.env.API_URL}/oauth2/authorization/sh`;
  };

  const loginUser = async (loginDetails: LoginDetails) => {
    await login(loginDetails.username, loginDetails.password).catch((e) => {
      if (e === AuthError.INVALID_CREDENTIALS) {
        showToast.error('Wrong credentials');
      } else {
        showToast.error('Could not log in');
      }
    });
  };

  return (
    <section className='max-w-[960px] mx-auto min-h-screen flex flex-row gap-4 justify-between items-center'>
      <div className='flex-grow flex-shrink-0 sm:min-w-sm sm:max-w-md mx-auto my-16'>
        <div className='mb-4'>
          <h1 className='font-display font-semibold text-2xl w-full'>
            Reserve.SH
          </h1>
          <p>Silicon Hill Reservation System</p>
        </div>
        <Card className='w-full mx-auto p-4'>
          <h2 className='font-display font-semibold text-3xl mb-4'>Log in</h2>
          <Button
            color={ButtonColor.PRIMARY_DARK}
            type={ButtonType.DEFAULT}
            icon={mdiLoginVariant}
            onClick={loginOauth}
            className='w-full'
          >
            Log in with Silicon Hill IS
          </Button>
          <p className='w-full my-4 text-gray-500 text-center'>or</p>
          <div className='w-full mx-auto'>
            <form>
              <div className='my-4'>
                <p className='font-semibold'>Username</p>
                <input
                  {...register('username', {
                    required: true,
                  })}
                  type='text'
                  placeholder='john.doe'
                  className={`form-input mt-2 mb-2 ${
                    errors.username ? 'border-error-500' : 'border-gray-300'
                  }`}
                />
                {errors.username && (
                  <p className='text-sm mb-2 text-error-700'>
                    Username is required
                  </p>
                )}
                <p className='font-semibold pt-2'>Password</p>
                <input
                  {...register('password', {
                    required: true,
                  })}
                  type='password'
                  placeholder='super_secret_password'
                  className={`form-input mt-2 mb-2 ${
                    errors.password ? 'border-error-500' : 'border-gray-300'
                  }`}
                />
                {errors.password && (
                  <p className='text-sm mb-2 text-error-700'>
                    Password is required
                  </p>
                )}
              </div>
              <Button
                color={ButtonColor.PRIMARY_LIGHT}
                type={ButtonType.DEFAULT}
                className='w-full'
                icon={mdiLoginVariant}
                onClick={handleSubmit(loginUser)}
              >
                Log in
              </Button>
            </form>
          </div>
          <div className='mt-4 border-t border-gray-300'>
            <Button
              color={ButtonColor.PRIMARY_LIGHTER}
              type={ButtonType.DEFAULT}
              className='w-full mt-4'
              icon={mdiAccountPlus}
              onClick={() => {
                navigate(routes.register);
              }}
            >
              Create account
            </Button>
          </div>
        </Card>
      </div>
      <img
        src={welcome}
        alt='"Welcome" illustration'
        className='h-full flex-shrink min-w-0 hidden md:block'
      />
    </section>
  );
};
