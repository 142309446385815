import { mdiPlus } from '@mdi/js';
import { useNavigate } from 'react-router-dom';
import { LocationCard } from 'src/components/cards/LocationCard';
import { Loading } from 'src/components/icons/Loading';
import { Header, HeaderType } from 'src/components/sections/Header';
import { routes } from 'src/routes';
import { useEditableLocations } from 'src/state/locations';
import {
  Button,
  ButtonColor,
  ButtonType,
} from '~src/components/controls/Button';
import { sortLocations } from '~src/utils/sorting';

export const ManageLocationScreen = () => {
  const editableLocations = useEditableLocations();
  const navigate = useNavigate();

  const getContent = () => {
    if (editableLocations.isLoading) {
      return <Loading />;
    }

    if ((editableLocations.data?.length ?? 0) === 0) {
      return <p>No managed locations</p>;
    }

    return (
      <div className='rounded-lg bg-white shadow-soft overflow-hidden'>
        {editableLocations?.data?.sort(sortLocations).map((location) => (
          <LocationCard
            key={location.id}
            location={location}
            onClick={() => navigate(routes.locationBySlug(location.slug))}
          />
        ))}
      </div>
    );
  };

  return (
    <section className='py-4'>
      <div className='sm:flex items-center my-4'>
        <Header
          text='Managed locations'
          type={HeaderType.SECTION}
          className='flex-grow'
        />
        <Button
          color={ButtonColor.PRIMARY_DARK}
          type={ButtonType.DEFAULT}
          icon={mdiPlus}
          onClick={() => navigate('new')}
          className='mt-4 sm:mt-0'
        >
          New location
        </Button>
      </div>
      {getContent()}
    </section>
  );
};
