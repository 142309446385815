import cx from 'classnames';
import { FallbackIcon } from 'src/components/icons/FallbackIcon';
import { LocationDTO } from 'src/generated/api';
import { ClassNameProps } from '~src/utils/ClassNameProps';
import { useImageById } from '~src/state/images';
import { Button, ButtonColor, ButtonType } from '../controls/Button';
import { mdiTrashCan } from '@mdi/js';

export interface LocationEditCardProps extends ClassNameProps {
  location: LocationDTO;
  onDelete: () => void;
}

export const LocationEditCard = (props: LocationEditCardProps) => {
  const image = useImageById(props.location.imageId);

  return (
    <article
      className={cx(
        'flex items-center py-1 border border-gray-500 rounded-lg h-[72px] mb-2',
        props.className
      )}
    >
      {
        /** TODO: Temporary override until images are fixed on BE */
        // eslint-disable-next-line no-constant-condition
        false && props.location.imageId ? (
          <img
            src={image?.data}
            alt={props.location.name}
            className='mx-4 my-2 w-12 h-12 object-cover rounded'
          />
        ) : (
          <FallbackIcon text={props.location.name ?? ''} />
        )
      }
      <div className='h-12 w-full flex items-center justify-between'>
        <p className='font-bold text-base text-gray-900'>
          {props.location.name}
        </p>
        <Button
          color={ButtonColor.ERROR}
          type={ButtonType.DEFAULT}
          icon={mdiTrashCan}
          onClick={props.onDelete}
          className='h-12 w-12 m-2'
        />
      </div>
    </article>
  );
};
