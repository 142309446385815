import { mdiCheckboxBlankCircleOutline, mdiCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { ClassNameProps } from '~src/utils/ClassNameProps';

export interface IndicatorProps extends ClassNameProps {
  text?: string;
  filled: boolean;
}

export const Indicator = (props: IndicatorProps) => {
  return (
    <p className={props.className}>
      <Icon
        className='inline-block align-baseline mr-1'
        path={props.filled ? mdiCircle : mdiCheckboxBlankCircleOutline}
        size='0.75rem'
      />
      {props.text && <span className='inline-block'>{props.text}</span>}
    </p>
  );
};
