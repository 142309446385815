import { useNavigate } from 'react-router-dom';
import { ItemDTO } from '~src/generated/api';
import { routes } from '~src/routes';
import { ItemCard } from '../cards/ItemCard';

export interface ItemListProps {
  items: ItemDTO[];
}

export const ItemList = ({ items }: ItemListProps) => {
  const navigate = useNavigate();

  if (items.length === 0) {
    return <p>No items.</p>;
  }

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
      {items.map((item) => (
        <ItemCard
          key={item.id}
          item={item}
          imageSrc=''
          onClick={() => navigate(routes.itemBySlug(item.slug))}
        />
      ))}
    </div>
  );
};
