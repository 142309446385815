import { Popover } from '@headlessui/react';
import { mdiLogoutVariant, mdiMenuDown } from '@mdi/js';
import Icon from '@mdi/react';
import { useAtomValue } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/routes';
import { useAuthActions } from 'src/state/auth';
import { Environment, environmentAtom } from 'src/state/environment';
import { useUserInfo } from '~src/state/user';
import { Button, ButtonColor, ButtonType } from './Button';
import { DebugPanel } from './DebugPanel';
import cx from 'classnames';

export const UserDropdown = () => {
  const environment = useAtomValue(environmentAtom);
  const authActions = useAuthActions();
  const { data: userInfo } = useUserInfo();
  const navigate = useNavigate();

  const logout = () => {
    authActions.logout();
    navigate(routes.main);
  };

  return (
    <Popover className='sm:relative sm:text-right'>
      <Popover.Button className='py-4 ml-2 md:ml-0'>
        <span className='inline-block'>
          {userInfo?.firstName} {userInfo?.lastName}
        </span>
        <Icon path={mdiMenuDown} className='inline-block h-4 w-4' />
      </Popover.Button>
      <Popover.Panel
        className={cx(
          'sm:absolute sm:right-4 z-30 bg-white shadow-soft p-4 rounded-lg text-left',
          { 'w-full sm:w-64': environment === Environment.DEVELOPMENT }
        )}
      >
        <Button
          color={ButtonColor.PRIMARY_DARK}
          type={ButtonType.DEFAULT}
          icon={mdiLogoutVariant}
          onClick={logout}
        >
          Log out
        </Button>
        {environment === Environment.DEVELOPMENT && <DebugPanel />}
      </Popover.Panel>
    </Popover>
  );
};
