/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RoleCreateDTO } from '../models/RoleCreateDTO';
import type { RoleDTO } from '../models/RoleDTO';
import type { RoleUpdateDTO } from '../models/RoleUpdateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoleControllerService {

    /**
     * @param id
     * @returns RoleDTO OK
     * @throws ApiError
     */
    public static findRoleById(
        id: number,
    ): CancelablePromise<RoleDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/role/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns RoleDTO OK
     * @throws ApiError
     */
    public static updateRole(
        id: number,
        requestBody: RoleUpdateDTO,
    ): CancelablePromise<RoleDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/role/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteRole(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/role/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns RoleDTO OK
     * @throws ApiError
     */
    public static allRoles(): CancelablePromise<Array<RoleDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/role',
        });
    }

    /**
     * @param requestBody
     * @returns RoleDTO Created
     * @throws ApiError
     */
    public static saveRole(
        requestBody: RoleCreateDTO,
    ): CancelablePromise<RoleDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/role',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}