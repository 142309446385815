import { mdiMenuRight } from '@mdi/js';
import Icon from '@mdi/react';
import { useNavigate } from 'react-router-dom';

export interface PageLink {
  name: string;
  route?: string;
}

export interface BreadcrumbsProps {
  links: PageLink[];
}

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const navigate = useNavigate();

  const arrow = (key: number) => (
    <Icon
      className='inline-block mr-1 align-sub'
      path={mdiMenuRight}
      size='1rem'
      key={key}
    />
  );

  const getBreadcrumb = (link: PageLink) => {
    return (
      <p
        className='mr-1 cursor-pointer inline-block hover:text-gray-700'
        key={link.name}
      >
        <a onClick={() => link.route && navigate(link.route)}>{link.name}</a>
      </p>
    );
  };

  return (
    <nav>
      {props.links.flatMap((link, index, array) => {
        return index !== array.length - 1
          ? [getBreadcrumb(link), arrow(index)]
          : getBreadcrumb(link);
      })}
    </nav>
  );
};
