import { mdiMenu } from '@mdi/js';
import Icon from '@mdi/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/routes';
import { Nav, NavOrientation } from './Nav';
import { UserDropdown } from './UserDropdown';

export const TopBar = () => {
  const navigate = useNavigate();
  const [isMobileExpanded, setMobileExpanded] = useState<boolean>(false);

  const toggleMobilePanel = () => {
    setMobileExpanded(!isMobileExpanded);
  };

  const mobilePanelClass = isMobileExpanded ? 'block' : 'hidden';

  return (
    <header className='w-full min-h-[4rem] border-t-4 border-primary-500 sm:flex gap-4 align-middle justify-between items-center z-20 top-0'>
      <div className='flex justify-between items-center sm:block flex-grow basis-0 px-6'>
        <h1 className='inline-block select-none font-display text-2xl font-semibold'>
          <a className='cursor-pointer' onClick={() => navigate(routes.main)}>
            <span className='block sm:hidden md:block'>Reserve.SH</span>
            <span className='hidden sm:block md:hidden'>RS.SH</span>
          </a>
        </h1>
        <div
          className='block sm:hidden cursor-pointer my-4'
          onClick={toggleMobilePanel}
        >
          <Icon path={mdiMenu} size='2rem' />
        </div>
      </div>
      <Nav orientation={NavOrientation.HORIZONTAL} className='hidden sm:flex' />
      <div
        className={`${mobilePanelClass} px-6 sm:block flex-shrink-0 flex-grow basis-0`}
      >
        <Nav
          orientation={NavOrientation.VERTICAL}
          onNavigate={() => setMobileExpanded(false)}
          className='flex sm:hidden'
        />
        <div className='sm:hidden py-2 border-b border-gray-300' />
        <UserDropdown />
      </div>
    </header>
  );
};
