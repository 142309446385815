import { mdiClose, mdiContentSave, mdiPlus } from '@mdi/js';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  ButtonColor,
  ButtonType,
} from 'src/components/controls/Button';
import { RoleEditCard } from '~src/components/cards/RoleEditCard';
import { Header, HeaderType } from '~src/components/sections/Header';
import { PickDialog } from '~src/components/sections/PickDialog';
import { RoleDTO } from '~src/generated/api';
import { useEditableRoles } from '~src/state/roles';
import { useStateList } from '~src/utils/useStateList';

export interface EditUserData {
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  active?: boolean;
  roles?: RoleDTO[];
}

export interface EditUserScreenProps {
  title: string;
  data?: EditUserData;
  onSubmit: (user: EditUserData) => void | Promise<void>;
  onCancel: () => void;
}

export const EditUserScreen = ({
  title,
  data,
  onSubmit,
  onCancel,
}: EditUserScreenProps) => {
  const [active, setActive] = useState<boolean>(data?.active ?? false);

  const {
    state: roles,
    add: addRole,
    remove: removeRole,
  } = useStateList(data?.roles ?? [], (id, item) => id === item.id);
  const [rolePickerVisible, setRolePickerVisible] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    // formState: { errors }, TODO: add error handling
  } = useForm({ defaultValues: data });

  const managedRoles = useEditableRoles();

  const submit = async (user: {
    firstName?: string;
    lastName?: string;
    username?: string;
    email?: string;
  }) => {
    await onSubmit({ ...user, active, roles });
  };

  const cancel = (e: React.FormEvent) => {
    e.preventDefault();
    onCancel();
  };

  return (
    <>
      <PickDialog
        title='Add role'
        description='Choose a role to add'
        options={
          managedRoles.data
            ?.filter((role) => !roles.map((role) => role.id).includes(role.id))
            ?.map((role) => ({
              name: `${role.name ?? 'Unknown role'} ${
                role.admin ? '[ADMIN]' : ''
              }`,
              value: role,
            })) ?? []
        }
        visible={rolePickerVisible}
        onClose={() => setRolePickerVisible(false)}
        onPick={(role?: RoleDTO) => role && addRole(role)}
      />
      <div className='my-4'>
        <Header text={title} type={HeaderType.SCREEN} className='mb-4' />
        <form onSubmit={handleSubmit(submit)}>
          <section className='my-4'>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 my-4'>
              <label className='block font-semibold'>
                First name
                <input
                  {...register('firstName', { required: true })}
                  className='form-input border-gray-300'
                  type='text'
                />
              </label>
              <label className='block font-semibold'>
                Last name
                <input
                  {...register('lastName', { required: true })}
                  className='form-input border-gray-300'
                  type='text'
                />
              </label>
            </div>
            <label className='block font-semibold my-4'>
              Username
              <input
                {...register('username', { required: true })}
                className='form-input border-gray-300'
                type='text'
              />
            </label>
            <label className='block font-semibold my-4'>
              Email
              <input
                {...register('email', {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
                className='form-input border-gray-300'
                type='text'
              />
            </label>
            <label className='my-4 flex items-center w-full cursor-pointer'>
              <input
                {...register('active', {
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    setActive(e.target.checked),
                })}
                className='peer sr-only'
                type='checkbox'
              />
              <div className='mr-4 flex-grow inline-block'>
                <p className='font-semibold'>
                  {active ? 'Account active' : 'Account disabled'}
                </p>
                <p className='text-sm text-gray-700'>
                  Indicates whether the user can access the system.
                </p>
              </div>
              <div className='flex-shrink-0 form-checkbox-toggle' />
            </label>
          </section>

          <section className='my-8'>
            <div className='sm:flex'>
              <Header
                text='Roles'
                type={HeaderType.SECTION}
                className='flex-grow'
              />
              <Button
                color={ButtonColor.PRIMARY_LIGHT}
                type={ButtonType.DEFAULT}
                icon={mdiPlus}
                onClick={(e) => {
                  e.preventDefault();
                  setRolePickerVisible(true);
                }}
                className='mt-4 sm:mt-0'
              >
                Add role
              </Button>
            </div>
            {roles.length === 0 ? (
              <p className='text-gray-700 my-2'>No roles</p>
            ) : (
              <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 my-4'>
                {roles.map((role) => (
                  <RoleEditCard
                    key={role.id}
                    role={role}
                    onDelete={() => role.id && removeRole(role.id)}
                  />
                ))}
              </div>
            )}
          </section>

          <section className='my-4'>
            <Button
              color={ButtonColor.PRIMARY_DARK}
              type={ButtonType.DEFAULT}
              icon={mdiContentSave}
              className='mr-2'
            >
              Save
            </Button>
            <Button
              color={ButtonColor.WHITE}
              type={ButtonType.DEFAULT}
              icon={mdiClose}
              onClick={cancel}
            >
              Cancel
            </Button>
          </section>
        </form>
      </div>
    </>
  );
};
