/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ItemCreateDTO } from '../models/ItemCreateDTO';
import type { ItemDTO } from '../models/ItemDTO';
import type { ItemUpdateDTO } from '../models/ItemUpdateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ItemControllerService {

    /**
     * @param id
     * @returns ItemDTO OK
     * @throws ApiError
     */
    public static findItemById(
        id: number,
    ): CancelablePromise<ItemDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/item/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param formData
     * @returns ItemDTO OK
     * @throws ApiError
     */
    public static updateItem(
        id: number,
        formData?: {
            data: ItemUpdateDTO;
            image?: Blob;
        },
    ): CancelablePromise<ItemDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/item/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteItem(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/item/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ItemDTO OK
     * @throws ApiError
     */
    public static allItems(): CancelablePromise<Array<ItemDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/item',
        });
    }

    /**
     * @param formData
     * @returns ItemDTO Created
     * @throws ApiError
     */
    public static saveItem(
        formData?: {
            data: ItemCreateDTO;
            image?: Blob;
        },
    ): CancelablePromise<ItemDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/item',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param itemId
     * @param tagId
     * @returns ItemDTO Created
     * @throws ApiError
     */
    public static addTagToItem(
        itemId: number,
        tagId: number,
    ): CancelablePromise<ItemDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/item/{itemId}/tag/{tagId}',
            path: {
                'itemId': itemId,
                'tagId': tagId,
            },
        });
    }

    /**
     * @param userId
     * @returns ItemDTO OK
     * @throws ApiError
     */
    public static getUsersFavouriteItems(
        userId: number,
    ): CancelablePromise<Array<ItemDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/{userId}/favourite',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param locationId
     * @returns ItemDTO OK
     * @throws ApiError
     */
    public static getItemsByLocation(
        locationId: number,
    ): CancelablePromise<Array<ItemDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location/{locationId}/item',
            path: {
                'locationId': locationId,
            },
        });
    }

    /**
     * @param tagId
     * @returns ItemDTO OK
     * @throws ApiError
     */
    public static getItemsByTag(
        tagId: number,
    ): CancelablePromise<Array<ItemDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/item/tag/{tagId}',
            path: {
                'tagId': tagId,
            },
        });
    }

    /**
     * @param slug
     * @returns ItemDTO OK
     * @throws ApiError
     */
    public static findItemBySlug(
        slug: string,
    ): CancelablePromise<ItemDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/item/',
            query: {
                'slug': slug,
            },
        });
    }

}