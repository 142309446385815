import { mdiDelete, mdiPencil } from '@mdi/js';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  ButtonColor,
  ButtonType,
} from '~src/components/controls/Button';
import { Breadcrumbs } from '~src/components/icons/Breadcrumbs';
import { Loading } from '~src/components/icons/Loading';
import { DeleteDialog } from '~src/components/sections/DeleteDialog';
import { ErrorMessage } from '~src/components/sections/ErrorMessage';
import { Header, HeaderType } from '~src/components/sections/Header';
import { ItemList } from '~src/components/sections/ItemList';
import { NotFoundMessage } from '~src/components/sections/NotFoundMessage';
import { routes } from '~src/routes';
import {
  EditTagData,
  EditTagScreen,
} from '~src/screens/manage/tag/EditTagScreen';
import { useItemsByTag } from '~src/state/items';
import {
  useDeleteTag,
  useEditableTags,
  useTagById,
  useUpdateTag,
} from '~src/state/tags';
import { showToast } from '~src/utils/toast';

export const TagScreen = () => {
  const urlParams = useParams();
  const navigate = useNavigate();

  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [isEditVisible, setEditVisible] = useState<boolean>(false);

  const tagId = Number(urlParams.tagId);
  const tag = useTagById(tagId);
  const items = useItemsByTag(tagId);

  const updateTag = useUpdateTag();
  const deleteTag = useDeleteTag();

  const editableTags = useEditableTags();

  if (urlParams.tagId === undefined || isNaN(tagId)) {
    return <NotFoundMessage />;
  }

  const getContent = () => {
    if (tag.error || items.error) {
      return <ErrorMessage />;
    }

    if (tag.isLoading || items.isLoading) {
      return <Loading />;
    }

    if (items.data) {
      return (
        <>
          <Header
            type={HeaderType.SECTION}
            text='Items with this tag'
            className='py-4'
          />
          <ItemList items={items.data} />
        </>
      );
    }
  };

  if (isEditVisible) {
    return (
      <EditTagScreen
        title='Edit tag'
        data={tag.data}
        onSubmit={(newTag: EditTagData) => {
          updateTag
            .mutateAsync({ tagId, tag: newTag })
            .then(() => {
              showToast.success('Tag updated successfully');
              setEditVisible(false);
            })
            .catch(() => {
              showToast.error('Could not update tag');
            });
        }}
        onCancel={() => setEditVisible(false)}
      />
    );
  }

  const tagName = tag.data?.name ?? 'Tag';
  return (
    <>
      <DeleteDialog
        title='Delete tag'
        description='This will delete the tag!'
        text='Are you sure you want to delete this tag?'
        visible={isDeleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDelete={() => {
          deleteTag
            .mutateAsync(tag.data?.id)
            .then(() => {
              showToast.success('Tag deleted successfully');
              navigate(routes.browse);
            })
            .catch(() => {
              showToast.error('Could not delete tag');
            });
        }}
      />
      <div className='my-4'>
        <Breadcrumbs
          links={[{ name: 'Browse', route: routes.browse }, { name: tagName }]}
        />
        <section className='my-4'>
          <Header text={tagName} type={HeaderType.SCREEN} />
        </section>
        {tag.data !== undefined &&
          editableTags.data?.map((tag) => tag.id).includes(tag.data.id) && (
            <section className='flex flex-wrap flex-row gap-x-4 gap-y-2 my-4'>
              <Button
                icon={mdiPencil}
                color={ButtonColor.WHITE}
                type={ButtonType.DEFAULT}
                onClick={() => {
                  setEditVisible(true);
                }}
              >
                Edit
              </Button>
              <Button
                icon={mdiDelete}
                color={ButtonColor.ERROR}
                type={ButtonType.DEFAULT}
                onClick={() => {
                  setDeleteOpen(true);
                }}
              >
                Delete
              </Button>
            </section>
          )}
        <section className='my-4'>{getContent()}</section>
      </div>
    </>
  );
};
