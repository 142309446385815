import { useNavigate } from 'react-router-dom';
import { FavoriteCard } from 'src/components/cards/FavoriteCard';
import { HeaderType, Header } from 'src/components/sections/Header';
import { ItemDTO } from 'src/generated/api';
import { FavoritePlaceholder } from 'src/components/cards/FavoritePlaceholder';
import { useFavoriteItems } from 'src/state/items';
import { Loading } from 'src/components/icons/Loading';
import { routes } from 'src/routes';
import cx from 'classnames';
import { ErrorMessage } from '~src/components/sections/ErrorMessage';

export const Favorites = () => {
  const favoriteItems = useFavoriteItems();

  const navigate = useNavigate();

  const placeholder = () => {
    return (
      <FavoritePlaceholder
        key='placeholder'
        className='h-full hidden md:flex'
      />
    );
  };

  const card = (item: ItemDTO) => (
    <FavoriteCard
      key={item.id}
      item={item}
      onClick={() => navigate(routes.itemBySlug(item.slug))}
      className='mb-4 md:mb-0'
    />
  );

  let content;
  if (favoriteItems.isLoading) {
    content = <Loading />;
  } else if (favoriteItems.isError) {
    content = <ErrorMessage />;
  } else if (favoriteItems.data) {
    if (favoriteItems.data.length > 0) {
      content = (
        <div className='md:grid grid-cols-2 gap-4 grid-flow-row'>
          {favoriteItems.data.map(card)}
          {Array(favoriteItems.data.length % 2)
            .fill(undefined)
            .map(placeholder)}
        </div>
      );
    }
  }

  return (
    <section className='my-8'>
      <Header
        text={
          favoriteItems.data?.length !== 0
            ? 'Favorites'
            : 'You have no favorites'
        }
        type={
          favoriteItems.data?.length !== 0
            ? HeaderType.SECTION
            : HeaderType.SUBSECTION
        }
        className={cx({ 'pb-4': favoriteItems.data?.length !== 0 })}
      />
      {content}
    </section>
  );
};
