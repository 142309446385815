import { decodeJwt } from 'jose';
import { atom, useSetAtom } from 'jotai';
import { atomWithStorage, RESET } from 'jotai/utils';
import {
  ApiError,
  AuthenticateControllerService,
  OpenAPI,
} from 'src/generated/api';

// the type is null on no value as undefined cannot be deserialized
export const refreshTokenAtom = atomWithStorage<string | null>(
  'refresh_token',
  null
);

const authTokenValueAtom = atom<string | undefined>(undefined);

const getAuthToken = async (refreshToken: string) => {
  OpenAPI.TOKEN = undefined;
  return await AuthenticateControllerService.getJwt(refreshToken).then(
    (res) => res.jwt
  );
};

export const authTokenAtom = atom(
  (get) => get(authTokenValueAtom),
  async (get, set, update: string | undefined | typeof RESET) => {
    if (update === RESET) {
      // refetch auth token
      set(authTokenValueAtom, undefined);
      const refreshToken = get(refreshTokenAtom);
      if (refreshToken === null) {
        return;
      }
      update = await getAuthToken(refreshToken);
    }
    OpenAPI.TOKEN = update;
    set(authTokenValueAtom, update);
  }
);

export const authTokenPayloadAtom = atom((get) => {
  const authToken = get(authTokenValueAtom);
  return authToken === undefined ? undefined : decodeJwt(authToken);
});

export const currentUserIdAtom = atom((get) => {
  const payload = get(authTokenPayloadAtom);
  return payload === undefined ? undefined : Number(payload['userId']);
});

export enum AuthError {
  INVALID_CREDENTIALS,
  OAUTH_FAILED,
  UNKNOWN,
}

export const useAuthActions = () => {
  const setRefreshToken = useSetAtom(refreshTokenAtom);
  const setAuthToken = useSetAtom(authTokenAtom);

  const login = async (username: string, password: string) => {
    try {
      OpenAPI.TOKEN = undefined;
      const token = await AuthenticateControllerService.auth({
        username,
        password,
      }).then((holder) => holder.token);
      if (token === undefined) return;
      setRefreshToken(token);
      await setAuthToken(RESET);
    } catch (error) {
      if (error instanceof ApiError && error.status === 403) {
        return Promise.reject(AuthError.INVALID_CREDENTIALS);
      }
      return Promise.reject(AuthError.UNKNOWN);
    }
  };

  const logout = () => {
    setRefreshToken(null);
    void setAuthToken(undefined);
  };

  return { login, logout };
};

export const useOAuth = () => {
  const setRefreshToken = useSetAtom(refreshTokenAtom);
  const setAuthToken = useSetAtom(authTokenAtom);

  return async (code?: string, state?: string) => {
    if (code === undefined || state === undefined) {
      return Promise.reject(AuthError.UNKNOWN);
    }

    try {
      OpenAPI.TOKEN = undefined;
      const token = await AuthenticateControllerService.oauthLogin(
        code,
        state
      ).then((holder) => holder.token);
      if (token === undefined) throw new Error();
      setRefreshToken(token);
      await setAuthToken(RESET);
      return Promise.resolve();
    } catch {
      return Promise.reject(AuthError.OAUTH_FAILED);
    }
  };
};
