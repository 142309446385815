import cx from 'classnames';
import { ItemDTO } from '~src/generated/api';
import { useParentLocations } from '~src/state/locations';
import { ClassNameProps } from '~src/utils/ClassNameProps';
import { Card } from './Card';

export interface FavoriteCardProps extends ClassNameProps {
  item: ItemDTO;
  onClick: () => void;
}

export const FavoriteCard = (props: FavoriteCardProps) => {
  const locations = useParentLocations(props.item.locationId);

  const locationName =
    locations.data
      ?.map((loc) => loc.name)
      .reverse()
      .join(' / ') ?? 'Location';

  return (
    <Card
      className={cx('flex items-stretch', props.className)}
      onClick={props.onClick}
    >
      <img
        className='w-16 object-cover bg-gray-500 border border-gray-500'
        src=''
        alt={props.item.name}
      />
      <div className='px-4 py-3'>
        <h2 className='text-sm text-gray-700 italic'>{locationName}</h2>
        <h1 className='font-semibold'>{props.item.name}</h1>
      </div>
    </Card>
  );
};
