import { useNavigate } from 'react-router-dom';
import { useCreateLocation } from 'src/state/locations';
import { showToast } from 'src/utils/toast';
import { routes } from '~src/routes';
import { EditLocationData, EditLocationScreen } from './EditLocationScreen';

export const NewLocationScreen = () => {
  const navigate = useNavigate();

  const createLocation = useCreateLocation();

  const addLocation = ({
    name,
    description,
    slug,
    parentLocationId,
    image,
  }: EditLocationData) => {
    createLocation
      .mutateAsync({
        location: { name, description, slug, parentLocationId },
        image,
      })
      .then((location) => {
        showToast.success('Location added successfully');
        navigate(routes.locationBySlug(location.slug));
      })
      .catch(() => {
        showToast.error('Could not add location');
      });
  };

  return (
    <EditLocationScreen
      title='New location'
      onSubmit={addLocation}
      onCancel={() => navigate(-1) /* go back */}
    />
  );
};
