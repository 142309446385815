import cx from 'classnames';
import { ReservationDTO } from 'src/generated/api';
import { ClassNameProps } from '~src/utils/ClassNameProps';
import { Indicator, IndicatorProps } from './Indicator';

import ReservationState = ReservationDTO.state;

export interface ReservationIndicatorProps extends ClassNameProps {
  state: ReservationState;
}

export const ReservationIndicator = (props: ReservationIndicatorProps) => {
  if (props.state === ReservationState.COMPLETED) {
    return <></>;
  }

  const indicatorPropsFromState = (state: ReservationState): IndicatorProps => {
    if (state === ReservationState.CONFIRMED)
      return {
        className: 'text-primary-700',
        filled: true,
        text: 'Confirmed',
      };

    if (state === ReservationState.DENIED)
      return {
        className: 'text-error-700',
        filled: true,
        text: 'Denied',
      };

    if (state === ReservationState.CANCELLED)
      return {
        className: 'text-gray-700',
        filled: true,
        text: 'Cancelled',
      };

    return {
      className: 'text-warning-700',
      filled: false,
      text: 'Unconfirmed',
    };
  };

  const indicatorProps = indicatorPropsFromState(props.state);

  return (
    <Indicator
      {...indicatorProps}
      className={cx(indicatorProps.className, props.className)}
    />
  );
};
