import { mdiClose, mdiContentSave } from '@mdi/js';
import { useForm } from 'react-hook-form';
import {
  Button,
  ButtonColor,
  ButtonType,
} from 'src/components/controls/Button';
import { Header, HeaderType } from '~src/components/sections/Header';

export interface EditTagData {
  name?: string;
}

export interface EditTagScreenProps {
  title: string;
  data?: EditTagData;
  onSubmit: (tag: EditTagData) => void | Promise<void>;
  onCancel: () => void;
}

export const EditTagScreen = ({
  title,
  data,
  onSubmit,
  onCancel,
}: EditTagScreenProps) => {
  const {
    register,
    handleSubmit,
    // formState: { errors }, TODO: add error handling
  } = useForm({ defaultValues: data });

  const submit = async (tag: { name?: string }) => {
    await onSubmit(tag);
  };

  const cancel = (e: React.FormEvent) => {
    e.preventDefault();
    onCancel();
  };

  return (
    <div className='my-4'>
      <Header text={title} type={HeaderType.SCREEN} className='mb-4' />
      <form onSubmit={handleSubmit(submit)}>
        <section className='pb-2'>
          <label className='block font-semibold' htmlFor='name'>
            Name
          </label>
          <input
            {...register('name', { required: true })}
            className='form-input border-gray-300'
            type='text'
          />
        </section>

        <section className='py-4'>
          <Button
            color={ButtonColor.PRIMARY_DARK}
            type={ButtonType.DEFAULT}
            icon={mdiContentSave}
            className='mr-2'
          >
            Save
          </Button>
          <Button
            color={ButtonColor.WHITE}
            type={ButtonType.DEFAULT}
            icon={mdiClose}
            onClick={cancel}
          >
            Cancel
          </Button>
        </section>
      </form>
    </div>
  );
};
