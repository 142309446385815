import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import {
  ItemControllerService,
  ItemDTO,
  LocationControllerService,
  LocationCreateDTO,
  LocationDTO,
  LocationUpdateDTO,
} from 'src/generated/api';
import { useEditable } from './editable';
import { QueryType, useQuerySettings } from './querySettings';
import { useUserRoles } from './roles';

export const locationKeys = {
  all: () => ['items'] as const,
  list: () => [...locationKeys.all(), 'list'] as const,

  allList: () => [...locationKeys.list(), 'all'] as const,
  root: () => [...locationKeys.list(), 'root'] as const,
  managed: () => [...locationKeys.list(), 'managed'] as const,
  parents: (id?: number) => [...locationKeys.list(), 'parents', id] as const,

  detail: () => [...locationKeys.all(), 'detail'] as const,

  bySlug: (slug: string) => [...locationKeys.detail(), 'slug', slug] as const,
  byId: (id?: number) => [...locationKeys.detail(), 'id', id] as const,

  treeById: (id?: number) => [...locationKeys.all(), 'tree', id] as const,
};

export const useRootLocations = () => {
  // TODO: replace with dedicated root locations endpoint once created
  const allLocations = useAllLocations();
  const querySettings = useQuerySettings();
  return useQuery<LocationDTO[], Error>(
    locationKeys.root(),
    () => {
      return (
        allLocations.data?.filter((loc) => loc.parentLocationId === null) ?? []
      );
    },
    {
      ...querySettings,
      enabled: querySettings.enabled && allLocations.data !== undefined,
    }
  );
};

export const useLocationBySlug = (slug?: string) => {
  const querySettings = useQuerySettings();
  return useQuery<LocationDTO | undefined, Error>(
    locationKeys.bySlug(slug ?? ''),
    async () => {
      // TODO: Replace with search once API is complete
      const matchingLocations = (
        await LocationControllerService.allLocations()
      ).filter((loc) => loc.slug === slug);
      if (matchingLocations.length === 0) throw Error();
      return matchingLocations[0];
    },
    {
      ...querySettings,
      enabled: querySettings.enabled && slug !== undefined,
    }
  );
};

export const useLocationById = (id?: number) => {
  const querySettings = useQuerySettings();
  return useQuery<LocationDTO | undefined, Error>(
    locationKeys.byId(id),
    () => fetchLocationById(id as number),
    {
      ...querySettings,
      enabled: querySettings.enabled && id !== undefined && id !== null,
    }
  );
};

export interface LocationItems {
  name?: string;
  slug?: string;
  items: ItemDTO[];
}

export const useItemsByLocation = (location?: LocationDTO) => {
  const querySettings = useQuerySettings();
  return useQuery<LocationItems[], Error>(
    locationKeys.treeById(location?.id),
    async () => {
      // runs only if enabled is true
      const locations = await LocationControllerService.allLocationChildren(
        location?.id as number
      );
      locations.unshift(location as LocationDTO);

      return Promise.all(
        locations.map(
          async (location) =>
            ({
              name: location.name,
              slug: location.slug,
              items:
                location.id === undefined
                  ? []
                  : await ItemControllerService.getItemsByLocation(location.id),
            } as LocationItems)
        )
      );
    },
    {
      ...querySettings,
      enabled: querySettings.enabled && location?.id !== undefined,
    }
  );
};

const fetchLocationById = (id: number) =>
  LocationControllerService.findLocationById(id);

export const useLocationList = (locationIds: number[], enabled: boolean) => {
  const querySettings = useQuerySettings();
  return useQueries(
    locationIds.map((locationId) => ({
      queryKey: locationKeys.byId(locationId),
      queryFn: () => fetchLocationById(locationId),
      ...querySettings,
      enabled: querySettings.enabled && enabled,
    }))
  );
};

export const useAllLocations = (enabled = true) => {
  const querySettings = useQuerySettings();

  return useQuery(
    locationKeys.allList(),
    async () => {
      return await LocationControllerService.allLocations();
    },
    {
      ...querySettings,
      enabled: querySettings.enabled && enabled,
    }
  );
};

export const useManagedLocations = (enabled = true) => {
  const roles = useUserRoles();

  return useLocationList(
    [...new Set(roles.flatMap((role) => role.data?.managedLocationIds ?? []))],
    enabled
  );
};

export const useEditableLocations = useEditable(
  useAllLocations,
  useManagedLocations
);

export const useParentLocations = (locationId?: number) => {
  const querySettings = useQuerySettings(QueryType.LOCATION_TREE);
  return useQuery<LocationDTO[], Error>(
    locationKeys.parents(locationId),
    async () => {
      if (locationId === undefined) return [];
      return await LocationControllerService.allLocationParents(locationId);
    },
    {
      ...querySettings,
      enabled: querySettings.enabled && locationId !== undefined,
    }
  );
};

export const useCreateLocation = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  const create = useMutation(
    async ({
      location,
      image,
    }: {
      location: LocationCreateDTO;
      image?: File;
    }) => {
      const formData = new FormData();
      formData.append(
        'data',
        new Blob([JSON.stringify(location)], { type: 'application/json' })
      );
      if (image !== undefined) {
        formData.append('image', image);
      }
      return await LocationControllerService.saveLocation({
        data: location,
        image,
      });
    },
    {
      ...querySettings,
      onSuccess: async () => {
        await queryClient.invalidateQueries(locationKeys.all());
      },
    }
  );

  return create;
};

export const useUpdateLocation = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  const update = useMutation(
    async ({
      id,
      location,
      image,
    }: {
      id: number;
      location: LocationUpdateDTO;
      image?: File;
    }) => {
      const formData = new FormData();
      formData.append(
        'data',
        new Blob([JSON.stringify(location)], { type: 'application/json' })
      );
      if (image !== undefined) {
        formData.append('image', image);
      }
      await LocationControllerService.updateLocation(id, {
        data: location,
        image,
      });
    },
    {
      ...querySettings,
      onSuccess: async () => {
        await queryClient.invalidateQueries(locationKeys.all());
      },
    }
  );

  return update;
};

export const useDeleteLocation = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  const _delete = useMutation(
    async (locationId: number) => {
      await LocationControllerService.deleteLocation(locationId);
    },
    {
      ...querySettings,
      onSuccess: async () => {
        await queryClient.invalidateQueries(locationKeys.all());
      },
    }
  );

  return _delete;
};
