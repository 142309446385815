import { mdiCancel, mdiClose } from '@mdi/js';
import { useForm } from 'react-hook-form';
import {
  Button,
  ButtonColor,
  ButtonType,
} from '~src/components/controls/Button';
import { CustomDialog } from '~src/components/sections/CustomDialog';
import { BanCreateDTO } from '~src/generated/api';
import { useBanUser } from '~src/state/user';
import { showToast } from '~src/utils/toast';

export const BanDialog = ({
  userId,
  visible,
  onClose,
}: {
  userId: number;
  visible: boolean;
  onClose: () => void;
}) => {
  const {
    register,
    handleSubmit,
    // formState: { errors }, TODO: add error handling
  } = useForm();

  const banUser = useBanUser();

  const submitBan = ({ expirationTime }: { expirationTime?: string }) => {
    if (expirationTime === undefined) return;

    const banCreateDTO: BanCreateDTO = {
      userId: userId,
      expirationDate: expirationTime.replace('T', ' ')?.concat(':00'),
    };

    banUser
      .mutateAsync(banCreateDTO)
      .then(() => {
        showToast.success('User banned successfully');
        onClose();
      })
      .catch(() => {
        showToast.error('Could not ban user');
      });
  };

  return (
    <CustomDialog
      title='Ban user'
      description='This will block the user from logging in!'
      visible={visible}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(submitBan)}>
        <div className='py-2'>
          <label className='block font-semibold' htmlFor='startTime'>
            Expiration time
          </label>
          <input
            {...register('expirationTime', { required: true })}
            className='form-input border-gray-300'
            type='datetime-local'
          />
        </div>
        <div className='mt-2 flex flex-row gap-4'>
          <Button
            color={ButtonColor.ERROR}
            type={ButtonType.DEFAULT}
            icon={mdiCancel}
            className='flex-grow'
          >
            Ban
          </Button>
          <Button
            color={ButtonColor.WHITE}
            type={ButtonType.DEFAULT}
            icon={mdiClose}
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
            className='flex-grow'
          >
            Cancel
          </Button>
        </div>
      </form>
    </CustomDialog>
  );
};
