import { useAtomValue } from 'jotai';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import {
  ItemControllerService,
  ItemCreateDTO,
  ItemDTO,
  UserControllerService,
} from 'src/generated/api';
import { currentUserIdAtom } from './auth';
import { useEditable } from './editable';
import { useQuerySettings } from './querySettings';
import { useUserRoles } from './roles';

export const itemKeys = {
  all: () => ['items'] as const,

  list: () => [...itemKeys.all(), 'list'] as const,
  favorites: () => [...itemKeys.list(), 'favorites'] as const,
  allItems: () => [...itemKeys.list(), 'all'] as const,
  byTag: (tagId?: number) => [...itemKeys.list(), 'tag', tagId] as const,

  detail: () => [...itemKeys.all(), 'detail'] as const,
  bySlug: (slug: string) => [...itemKeys.detail(), 'slug', slug] as const,
  byId: (id: number) => [...itemKeys.detail(), 'id', id] as const,
};

export const useItemBySlug = (slug: string) => {
  const querySettings = useQuerySettings();
  return useQuery<ItemDTO, Error>(
    itemKeys.bySlug(slug),
    async () => {
      return ItemControllerService.findItemBySlug(slug);
    },
    querySettings
  );
};

export const useItemById = (id: number) => {
  const querySettings = useQuerySettings();
  return useQuery<ItemDTO, Error>(
    itemKeys.byId(id),
    () => fetchItemById(id),
    querySettings
  );
};

export const useFavoriteItems = () => {
  const querySettings = useQuerySettings();
  const currentUserId = useAtomValue(currentUserIdAtom);
  return useQuery<ItemDTO[], Error>(
    itemKeys.favorites(),
    async () => {
      if (currentUserId === undefined) return [];
      return ItemControllerService.getUsersFavouriteItems(currentUserId);
    },
    {
      ...querySettings,
      enabled: querySettings.enabled && currentUserId !== undefined,
    }
  );
};

export const useItemsByTag = (tagId?: number) => {
  const querySettings = useQuerySettings();
  return useQuery<ItemDTO[], Error>(
    itemKeys.byTag(tagId),
    async () => {
      return await ItemControllerService.getItemsByTag(tagId as number);
    },
    { ...querySettings, enabled: querySettings.enabled && tagId !== undefined }
  );
};

export const useUserItemActions = () => {
  const currentUserId = useAtomValue(currentUserIdAtom);
  const querySettings = useQuerySettings();
  const queryClient = useQueryClient();

  const favoriteSettings = {
    ...querySettings,
    onSuccess: async () => {
      await queryClient.invalidateQueries(itemKeys.favorites());
    },
  };

  const handleFavorite =
    (fn: (currentUserId: number, itemId: number) => Promise<unknown>) =>
    async (itemId?: number) => {
      if (currentUserId === undefined) throw new Error('Undefined user ID');
      if (itemId === undefined) throw new Error('Undefined item ID');
      await fn(currentUserId, itemId);
    };

  const addFavorite = useMutation(
    handleFavorite((currentUserId, itemId) =>
      UserControllerService.addFavouriteItem(currentUserId, itemId)
    ),
    favoriteSettings
  );

  const removeFavorite = useMutation(
    handleFavorite((currentUserId, itemId) =>
      UserControllerService.deleteFavouriteItem(currentUserId, itemId)
    ),
    favoriteSettings
  );

  const deleteItem = useMutation(
    async (itemId?: number) => {
      if (itemId === undefined) throw new Error('Undefined item ID');
      await ItemControllerService.deleteItem(itemId);
    },
    {
      ...querySettings,
      onSuccess: async () => {
        await queryClient.invalidateQueries(itemKeys.all());
      },
    }
  );

  return { addFavorite, removeFavorite, deleteItem };
};

export const useManagedItemIds = () => {
  const roles = useUserRoles();
  const querySettings = useQuerySettings();

  return useQuery<number[], Error>(
    ['user', 'current', 'managedItemIds'],
    () => {
      return [
        ...new Set(roles.flatMap((role) => role.data?.managedItemIds ?? [])),
      ];
    },
    {
      ...querySettings,
      enabled:
        querySettings.enabled && roles.every((role) => role.data !== undefined),
    }
  );
};

export const useAllItems = (enabled = true) => {
  const querySettings = useQuerySettings();
  return useQuery<ItemDTO[], Error>(
    ['user', 'current', 'managedItems'],
    async () => {
      return await ItemControllerService.allItems();
    },
    {
      ...querySettings,
      enabled: querySettings.enabled && enabled,
    }
  );
};

const fetchItemById = (id: number) => ItemControllerService.findItemById(id);

export const useItemList = (itemIds: number[], enabled: boolean) => {
  const querySettings = useQuerySettings();
  return useQueries(
    itemIds.map((itemId) => ({
      queryKey: itemKeys.byId(itemId),
      queryFn: () => fetchItemById(itemId),
      ...querySettings,
      enabled: querySettings.enabled && enabled,
    }))
  );
};

export const useManagedItems = (enabled = true) => {
  const managedItemIds = useManagedItemIds();
  return useItemList(
    managedItemIds.data ?? [],
    enabled && managedItemIds.data !== undefined
  );
};

export const useEditableItems = useEditable(useAllItems, useManagedItems);

export const useCreateItem = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  const create = useMutation(
    async ({ item, image }: { item: ItemCreateDTO; image?: File }) => {
      const formData = new FormData();
      formData.append(
        'data',
        new Blob([JSON.stringify(item)], { type: 'application/json' })
      );
      if (image !== undefined) {
        formData.append('image', image);
      }
      return await ItemControllerService.saveItem({
        data: item,
        image,
      });
    },
    {
      ...querySettings,
      onSuccess: async () => {
        await queryClient.invalidateQueries(itemKeys.all());
      },
    }
  );

  return create;
};

export const useUpdateItem = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  const update = useMutation(
    async ({
      id,
      item,
      image,
    }: {
      id: number;
      item: ItemCreateDTO;
      image?: File;
    }) => {
      const formData = new FormData();
      formData.append(
        'data',
        new Blob([JSON.stringify(item)], { type: 'application/json' })
      );
      if (image !== undefined) {
        formData.append('image', image);
      }
      return await ItemControllerService.updateItem(id, {
        data: item,
        image,
      });
    },
    {
      ...querySettings,
      onSuccess: async () => {
        await queryClient.invalidateQueries(itemKeys.all());
      },
    }
  );

  return update;
};
