import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useQueryClient } from 'react-query';
import { SECOND } from 'src/utils/timeUnits';
import { ApiError, OpenAPI } from '~/src/generated/api';
import { authTokenAtom } from './auth';

export enum QueryType {
  DEFAULT,
  LOCATION_TREE,
  USER_INFO,
}

const getStaleTime = (type?: QueryType) => {
  if (type === QueryType.LOCATION_TREE) {
    return 30 * SECOND;
  }
  if (type === QueryType.USER_INFO) {
    return 60 * SECOND;
  }
  return 0;
};

export const useQuerySettings = (queryType?: QueryType) => {
  const [authToken, setAuthToken] = useAtom(authTokenAtom);
  const queryClient = useQueryClient();

  const handleError = (error: ApiError) => {
    if (error.status === 401) {
      // token probably expired
      queryClient.clear();
      void setAuthToken(RESET);
    }
  };
  const handledStatuses = [401];

  const onError = (error: Error) => {
    if (error instanceof ApiError) {
      handleError(error);
    }
  };

  const retry = (failureCount: number, error: Error) => {
    if (error instanceof ApiError && handledStatuses.includes(error.status)) {
      return false;
    }
    return failureCount < 3;
  };

  return {
    onError,
    retry,
    enabled: OpenAPI.TOKEN !== undefined && authToken !== undefined,
    staleTime: getStaleTime(queryType),
  };
};
