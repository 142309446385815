import { Loading } from 'src/components/icons/Loading';
import { ReservationList } from 'src/components/sections/ReservationList';
import { HeaderType, Header } from 'src/components/sections/Header';
import { useUpcomingReservations } from 'src/state/reservations';
import { ErrorMessage } from '~src/components/sections/ErrorMessage';

export const UpcomingList = () => {
  const upcomingReservations = useUpcomingReservations();

  let content;
  if (upcomingReservations.isLoading) {
    content = <Loading />;
  } else if (upcomingReservations.isError) {
    content = <ErrorMessage />;
  } else if (upcomingReservations.data) {
    content = (
      <ReservationList
        reservations={upcomingReservations.data}
        showReservee={false}
        sorted
      />
    );
  }

  return (
    <section className='my-8'>
      {upcomingReservations?.data?.length === 0 ? (
        <Header
          text='There are no upcoming reservations'
          type={HeaderType.SUBSECTION}
        />
      ) : (
        <Header
          text='Upcoming'
          type={HeaderType.SECTION}
          count={upcomingReservations?.data?.length}
          className='pb-4'
        />
      )}
      {content}
    </section>
  );
};
