import { MINUTE, HOUR, DAY } from './timeUnits';

export const timestampToString = (time?: string | Date): string => {
  if (time === undefined || time === '') return 'Unknown time';
  let timestamp: number;
  if (time instanceof Date) {
    timestamp = time.getTime();
  } else {
    timestamp = Date.parse(time);
  }
  return new Intl.DateTimeFormat([], {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(timestamp);
};

/**
 * This function creates a string considering the plural of a word.
 * However, its implementation is the simplest it needs to be for
 * the purpose of this application.
 *
 * TODO: This function will be replaced by a localization library
 * that will actually use plurals properly in an upcoming version.
 */
export const toMultiple = (text: string, count: number) => {
  const output = `${count} ${text}`;
  if (count === 1 || count === -1) return output;
  return output + 's';
};

export const upcomingTimeString = (eventTime?: string): string => {
  if (eventTime === undefined || eventTime === '') return 'Start unknown';
  const eventTimestamp = Date.parse(eventTime);
  const currentTimestamp = Date.now();
  const timeDiff = eventTimestamp - currentTimestamp; // milliseconds
  if (timeDiff < 0) return 'Already started';
  if (timeDiff < MINUTE) return 'In a moment';

  const toTime = (count: number, unitText: string) => {
    const roundedCount = Math.floor(count);
    return `In ${toMultiple(unitText, roundedCount)}`;
  };

  if (timeDiff < HOUR) return toTime(timeDiff / MINUTE, 'minute');
  if (timeDiff < DAY) return toTime(timeDiff / HOUR, 'hour');
  return toTime(timeDiff / DAY, 'day');
};
