import { useQuery } from 'react-query';
import { useQuerySettings } from './querySettings';

export const useImageById = (imageId?: number) => {
  const querySettings = useQuerySettings();

  return useQuery(
    ['image', imageId],
    () => {
      // TODO: rework
      // this is broken since openapi-typescript-codegen does not support
      // binary types and the image requires an auth token to be retrieved
      //
      // const image = (await ImageControllerService.findImageById(
      //   imageId
      // )) as unknown;
      // const enc = new TextEncoder();
      // const blob = new Blob([enc.encode(image as string)], {
      //   type: 'image/png',
      // });
      // console.log('bob', blob.size, blob.type);
      // const string = 'data:;base64,' + btoa(toBinary(image as string));
      // console.log('str', string.length);
      // return string;
      if (!process.env.API_URL || imageId === undefined || imageId === null)
        return undefined;
      return `${process.env.API_URL}/image/${imageId}`;
    },
    {
      ...querySettings,
      enabled: querySettings.enabled && imageId !== undefined,
    }
  );
};
