import { useAtomValue } from 'jotai';
import {
  QueryKey,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  ItemControllerService,
  LocationControllerService,
  RoleControllerService,
  RoleCreateDTO,
  RoleDTO,
  RoleUpdateDTO,
  TagControllerService,
} from '~src/generated/api';
import { currentUserIdAtom } from './auth';
import { useEditable } from './editable';
import { useQuerySettings } from './querySettings';
import { useUserById } from './user';

const roleKeys = {
  all: () => ['roles'] as const,

  lists: () => [...roleKeys.all(), 'lists'] as const,
  allRoles: () => [...roleKeys.lists(), 'all'] as const,
  rolesForUser: (id?: number) => [...roleKeys.lists(), 'user', id] as const,
  managed: () => [...roleKeys.lists(), 'managed'] as const,

  detail: () => [...roleKeys.all(), 'detail'] as const,
  byId: (id?: number) => [...roleKeys.detail(), 'id', id] as const,

  itemsByRole: (roleId?: number) =>
    [...roleKeys.byId(roleId), 'items'] as const,
  locationsByRole: (roleId?: number) =>
    [...roleKeys.byId(roleId), 'locations'] as const,
  tagsByRole: (roleId?: number) => [...roleKeys.byId(roleId), 'tags'] as const,
  rolesByRole: (roleId?: number) =>
    [...roleKeys.byId(roleId), 'roles'] as const,
};

export const useUserRoles = () => {
  const currentUserId = useAtomValue(currentUserIdAtom);
  return useRolesForUser(currentUserId);
};

export const useAllRoles = (enabled = true) => {
  const querySettings = useQuerySettings();

  return useQuery<RoleDTO[], Error>(
    roleKeys.allRoles(),
    async () => {
      return await RoleControllerService.allRoles();
    },
    { ...querySettings, enabled: querySettings.enabled && enabled }
  );
};

const useRoleList = (roleIds: number[], enabled: boolean) => {
  const querySettings = useQuerySettings();
  return useQueries(
    roleIds.map((roleId) => ({
      queryKey: roleKeys.byId(roleId),
      queryFn: () => fetchRoleById(roleId),
      ...querySettings,
      enabled: querySettings.enabled && enabled,
    }))
  );
};

export const useManagedRoles = (enabled = true) => {
  const roles = useUserRoles();

  return useRoleList(
    [...new Set(roles?.flatMap((role) => role.data?.managedRoleIds ?? []))],
    enabled
  );
};

export const useEditableRoles = useEditable(useAllRoles, useManagedRoles);

const fetchRoleById = async (id: number) =>
  await RoleControllerService.findRoleById(id);

export const useRoleById = (id?: number) => {
  const querySettings = useQuerySettings();

  return useQuery(roleKeys.byId(id), async () => fetchRoleById(id as number), {
    ...querySettings,
    enabled: querySettings.enabled && id !== undefined && !isNaN(id),
  });
};

export const useDeleteRole = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  const _delete = useMutation(
    async (roleId?: number) => {
      if (roleId === undefined) return;
      await RoleControllerService.deleteRole(roleId);
    },
    {
      ...querySettings,
      onSuccess: async () => {
        await queryClient.invalidateQueries(roleKeys.all());
      },
    }
  );

  return _delete;
};

export const useCreateRole = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  const create = useMutation(
    async (data: RoleCreateDTO) => {
      return await RoleControllerService.saveRole(data);
    },
    {
      ...querySettings,
      onSuccess: async () => {
        await queryClient.invalidateQueries(roleKeys.all());
      },
    }
  );

  return create;
};

export const useUpdateRole = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  const update = useMutation(
    async ({ roleId, data }: { roleId?: number; data: RoleUpdateDTO }) => {
      if (roleId === undefined) return undefined;
      return await RoleControllerService.updateRole(roleId, data);
    },
    {
      ...querySettings,
      onSuccess: async (data?: RoleDTO) => {
        await queryClient.invalidateQueries(roleKeys.all());
        if (data !== undefined) {
          queryClient.setQueryData(roleKeys.byId(data.id), data);
          await queryClient.refetchQueries(roleKeys.byId(data.id));
        }
      },
    }
  );

  return update;
};

const useRoleDataList = <Type,>(
  key: QueryKey,
  findFn: (id: number) => Promise<Type>,
  ids: number[] | undefined
) => {
  const querySettings = useQuerySettings();

  return useQuery(
    key,
    async () => {
      if (ids === undefined) return [];
      return await Promise.all(ids.map(findFn));
    },
    { ...querySettings, enabled: querySettings.enabled && ids !== undefined }
  );
};

export const useRoleItems = (role?: RoleDTO) =>
  useRoleDataList(
    roleKeys.itemsByRole(role?.id),
    (itemId: number) => {
      return ItemControllerService.findItemById(itemId);
    },
    role?.managedItemIds
  );

export const useRoleLocations = (role?: RoleDTO) =>
  useRoleDataList(
    roleKeys.locationsByRole(role?.id),
    (locationId: number) => {
      return LocationControllerService.findLocationById(locationId);
    },
    [...new Set(role?.managedLocationIds)]
  );

export const useRoleTags = (role?: RoleDTO) =>
  useRoleDataList(
    roleKeys.tagsByRole(role?.id),
    (tagId: number) => {
      return TagControllerService.findTagById(tagId);
    },
    role?.managedTagIds
  );

export const useRoleRoles = (role?: RoleDTO) =>
  useRoleDataList(
    roleKeys.rolesByRole(role?.id),
    (roleId: number) => {
      return TagControllerService.findTagById(roleId);
    },
    role?.managedRoleIds
  );

export const useRolesForUser = (userId?: number) => {
  const querySettings = useQuerySettings();
  const { data: user } = useUserById(userId);
  return useQueries(
    user?.roleIds?.map((id) => ({
      queryKey: roleKeys.byId(id),
      queryFn: (): Promise<RoleDTO | null> => fetchRoleById(id),
      ...querySettings,
      enabled: querySettings.enabled && user !== undefined,
    })) ?? []
  );
};
