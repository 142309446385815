import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/routes';
import { LocationItems } from 'src/state/locations';
import { ItemList } from '~src/components/sections/ItemList';
const empty = new URL(
  '~/src/assets/undraw_void.svg',
  import.meta.url
).toString();

export interface LocationItemListProps {
  items: LocationItems;
  isRoot?: boolean;
}

export const LocationItemList = (props: LocationItemListProps) => {
  const navigate = useNavigate();

  return (
    <section
      className={cx('mb-8', { 'pt-8 border-t border-gray-300': !props.isRoot })}
    >
      {!props.isRoot && (
        <h2
          className={cx(
            'font-display font-semibold text-2xl text-gray-900 mb-4',
            {
              'cursor-pointer hover:text-gray-700': props.items.slug,
            }
          )}
          onClick={() => {
            if (props.items.slug !== undefined) {
              navigate(routes.locationBySlug(props.items.slug));
            }
          }}
        >
          {props.items.name}
        </h2>
      )}
      {props.items.items.length === 0 ? (
        <div className='w-full py-16'>
          <img
            src={empty}
            alt='"Empty" illustration'
            className='w-32 mx-auto'
          />
          <p className='mt-8 text-center'>No items here</p>
        </div>
      ) : (
        <ItemList items={props.items.items} />
      )}
    </section>
  );
};
