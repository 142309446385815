import { useNavigate } from 'react-router-dom';
import { RoleDTO } from '~src/generated/api';
import { routes } from '~src/routes';
import { useCreateRole } from '~src/state/roles';
import { showToast } from '~src/utils/toast';
import { EditRoleData, EditRoleScreen } from './EditRoleScreen';

export const NewRoleScreen = () => {
  const navigate = useNavigate();
  const createRole = useCreateRole();

  return (
    <EditRoleScreen
      title='New role'
      onSubmit={(newRole: EditRoleData) => {
        createRole
          .mutateAsync({
            name: newRole.name,
            admin: newRole.isAdmin,
            managedItemIds: newRole.roleItems.flatMap((item) => item.id ?? []),
            managedLocationIds: newRole.roleLocations.flatMap(
              (loc) => loc.id ?? []
            ),
            managedTagIds: newRole.roleTags.flatMap((tag) => tag.id ?? []),
            managedRoleIds: newRole.roleRoles.flatMap(
              (manRole) => manRole.id ?? []
            ),
          })
          .then((role?: RoleDTO) => {
            showToast.success('Role created successfully');
            navigate(
              role !== undefined ? routes.roleById(role.id) : routes.manage
            );
          })
          .catch(() => {
            showToast.error('Could not update role');
          });
      }}
      onCancel={() => navigate(-1)}
    />
  );
};
