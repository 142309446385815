import { useAtomValue } from 'jotai';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  AuthenticateControllerService,
  BanCreateDTO,
  UserControllerService,
  UserDTO,
  UserRegistration,
  UserUpdateDTO,
} from 'src/generated/api';
import { currentUserIdAtom } from './auth';
import { QueryType, useQuerySettings } from './querySettings';
import { useUserRoles } from './roles';

export const userKeys = {
  all: () => ['user'] as const,

  list: () => [...userKeys.all(), 'list'] as const,
  allUsers: () => [...userKeys.list(), 'all'] as const,

  detail: () => [...userKeys.all(), 'detail'] as const,
  byId: (id?: number) => [...userKeys.detail(), id] as const,

  rolesForId: (id?: number) => [...userKeys.byId(id), 'roles'] as const,

  isAdmin: () => [...userKeys.detail(), 'current', 'isAdmin'] as const,
};

export const useUserInfo = () => {
  const currentUserId = useAtomValue(currentUserIdAtom);
  const querySettings = useQuerySettings(QueryType.USER_INFO);

  return useQuery<UserDTO, Error>(
    userKeys.byId(currentUserId),
    async () => {
      return await UserControllerService.findUserById(currentUserId as number);
    },
    {
      ...querySettings,
      enabled: querySettings.enabled && currentUserId !== undefined,
    }
  );
};

export const useAllUsers = () => {
  const querySettings = useQuerySettings();
  return useQuery<UserDTO[], Error>(
    userKeys.allUsers(),
    async () => {
      return await UserControllerService.allUsers();
    },
    querySettings
  );
};

export const useUserById = (userId?: number) => {
  const querySettings = useQuerySettings();
  return useQuery<UserDTO, Error>(
    userKeys.byId(userId),
    async () => {
      return await UserControllerService.findUserById(userId as number);
    },
    {
      ...querySettings,
      enabled: querySettings.enabled && userId !== undefined && !isNaN(userId),
    }
  );
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  return useMutation(
    async (registration: UserRegistration) => {
      return await AuthenticateControllerService.register(registration);
    },
    {
      ...querySettings,
      onSuccess: async () => {
        await queryClient.invalidateQueries(userKeys.all());
      },
    }
  );
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  return useMutation(
    async ({ userId, user }: { userId: number; user: UserUpdateDTO }) => {
      return await UserControllerService.updateUser(userId, user);
    },
    {
      ...querySettings,
      onSuccess: async (user: UserDTO) => {
        await queryClient.invalidateQueries(userKeys.all());
        queryClient.setQueryData(userKeys.byId(user.id), user);
      },
    }
  );
};

export const useBanUser = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  return useMutation(
    async (data: BanCreateDTO) => {
      await UserControllerService.banUser(data);
    },
    {
      ...querySettings,
      onSuccess: async () => {
        await queryClient.invalidateQueries(userKeys.all());
      },
    }
  );
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const querySettings = useQuerySettings();

  return useMutation(
    async (userId?: number) => {
      if (userId === undefined) return;
      await UserControllerService.deleteUser(userId);
    },
    {
      ...querySettings,
      onSuccess: async () => {
        await queryClient.invalidateQueries(userKeys.all());
      },
    }
  );
};

export const useIsAdmin = () => {
  const querySettings = useQuerySettings();
  const userRoles = useUserRoles();

  return useQuery<boolean, Error>(
    userKeys.isAdmin(),
    () => {
      return userRoles.some((role) => role.data?.admin);
    },
    {
      ...querySettings,
      enabled:
        querySettings.enabled &&
        userRoles.every((role) => role.data !== undefined),
    }
  );
};
