import { mdiPlus } from '@mdi/js';
import { useNavigate } from 'react-router-dom';
import { RoleCard } from '~src/components/cards/RoleCard';
import {
  Button,
  ButtonColor,
  ButtonType,
} from '~src/components/controls/Button';
import { Loading } from '~src/components/icons/Loading';
import { Header, HeaderType } from '~src/components/sections/Header';
import { routes } from '~src/routes';
import { useEditableRoles } from '~src/state/roles';

export const ManageRoleScreen = () => {
  const navigate = useNavigate();

  const managedRoles = useEditableRoles();

  const getContent = () => {
    if (managedRoles.isLoading) {
      return <Loading />;
    }

    if ((managedRoles.data?.length ?? 0) === 0) {
      return <p>No managed roles</p>;
    }

    return (
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
        {managedRoles.data?.map((role) => (
          <RoleCard
            key={role.id}
            role={role}
            onClick={() => navigate(routes.roleById(role.id))}
          />
        ))}
      </div>
    );
  };

  return (
    <section className='py-4'>
      <div className='sm:flex items-center my-4'>
        <Header
          text='Managed roles'
          type={HeaderType.SECTION}
          className='flex-grow'
        />
        <Button
          color={ButtonColor.PRIMARY_DARK}
          type={ButtonType.DEFAULT}
          icon={mdiPlus}
          onClick={() => navigate('new')}
          className='mt-4 sm:mt-0'
        >
          New role
        </Button>
      </div>
      {getContent()}
    </section>
  );
};
