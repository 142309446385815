import { mdiTrashCan } from '@mdi/js';
import cx from 'classnames';
import { ItemDTO } from 'src/generated/api';
import { ClassNameProps } from '~src/utils/ClassNameProps';
import { Button, ButtonColor, ButtonType } from '../controls/Button';

export interface ItemEditCardProps extends ClassNameProps {
  item: ItemDTO;
  imageSrc?: string;
  onDelete?: () => void;
}

export const ItemEditCard = (props: ItemEditCardProps) => {
  return (
    <div
      className={cx(
        'flex justify-between border border-gray-500 overflow-clip rounded-lg',
        props.className
      )}
    >
      <div className='flex items-center'>
        <img
          className='w-16 h-full object-cover bg-gray-500 border border-gray-500'
          src={props.imageSrc}
          alt={props.item.name}
        />
        <h1 className='px-4 py-3 font-semibold'>{props.item.name}</h1>
      </div>
      <Button
        color={ButtonColor.ERROR}
        type={ButtonType.DEFAULT}
        icon={mdiTrashCan}
        onClick={props.onDelete}
        className='m-2 w-12 h-12'
      />
    </div>
  );
};
