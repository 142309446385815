import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiClose,
  mdiInformationOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import cx from 'classnames';
import toast, { Toast } from 'react-hot-toast';

interface ToastProps {
  message: string;
  icon: string;
  style: string;
  toast: Toast;
}

const CustomToast = (props: ToastProps) => (
  <div
    className={cx(
      'flex justify-between items-center max-w-sm shadow-mini z-50 rounded-lg',
      props.toast.visible ? 'animate-enter' : 'animate-leave',
      props.style
    )}
  >
    <p className='p-2'>
      <Icon path={props.icon} className='w-4 h-4 inline-block align-sub' />
      <span className='pl-2'>{props.message}</span>
    </p>
    <button
      className='cursor-pointer h-full p-2 align-sub'
      onClick={() => toast.dismiss(props.toast.id)}
    >
      <Icon path={mdiClose} className={'w-4 h-4 inline-block align-sub '} />
    </button>
  </div>
);

export const showToast = {
  success: (message: string) => {
    toast.custom((t) => (
      <CustomToast
        message={message}
        icon={mdiCheck}
        style='bg-primary-300 text-primary-900'
        toast={t}
      />
    ));
  },

  error: (message: string) => {
    toast.custom((t) => (
      <CustomToast
        message={message}
        icon={mdiAlertCircleOutline}
        style='bg-error-100 text-error-900'
        toast={t}
      />
    ));
  },

  info: (message: string) => {
    toast.custom((t) => (
      <CustomToast
        message={message}
        icon={mdiInformationOutline}
        style='bg-white text-gray-900'
        toast={t}
      />
    ));
  },
};
