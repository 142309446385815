import { UseQueryResult } from 'react-query';
import { useIsAdmin } from './user';

/**
 * This is a query hook extension that returns the objects which can be
 * edited by the currently logged in user.
 *
 * @param getAll      Query to get all objects
 * @param getManaged  Query to get managed objects
 * @returns           Query with editable objects
 */
export const useEditable =
  <Type,>(
    getAll: (enabled?: boolean) => UseQueryResult<Type[], Error>,
    getManaged: (enabled?: boolean) => UseQueryResult<Type, Error>[]
  ) =>
  () => {
    const { data: isUserAdmin } = useIsAdmin();
    const all = getAll(isUserAdmin === true);
    const managed = getManaged(isUserAdmin === false);

    if (isUserAdmin === undefined) {
      return { data: undefined, isLoading: true, error: false };
    }
    if (isUserAdmin) {
      return { data: all.data, isLoading: all.isLoading, error: all.isError };
    }
    return {
      data: managed.flatMap((res) => res.data ?? []),
      isLoading: managed.some((res) => res.isLoading),
      error: managed.every((res) => res.isError),
    };
  };
