import { mdiClose, mdiContentSave, mdiPlus, mdiUpload } from '@mdi/js';
import Icon from '@mdi/react';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  ButtonColor,
  ButtonType,
} from 'src/components/controls/Button';
import { Header, HeaderType } from '~src/components/sections/Header';
import { PickDialog } from '~src/components/sections/PickDialog';
import { Tag } from '~src/components/icons/Tag';
import { TagDTO } from '~src/generated/api';
import { useEditableLocations, useLocationById } from '~src/state/locations';
import { useEditableTags } from '~src/state/tags';

export interface EditItemData {
  name?: string;
  description?: string;
  slug?: string;
  locationId?: number;
  tagIds?: number[];
  image?: File;
  enabled?: boolean;
}

export interface EditItemScreenProps {
  title: string;
  data?: EditItemData;
  onSubmit: (item: EditItemData) => void | Promise<void>;
  onCancel: () => void;
}

export const EditItemScreen = ({
  title,
  data = { enabled: true },
  onSubmit,
  onCancel,
}: EditItemScreenProps) => {
  const [isAddTagVisible, setAddTagVisible] = useState<boolean>(false);

  const [enabled, setEnabled] = useState<boolean>(data?.enabled ?? false);
  const [image, setImage] = useState<File | undefined>(data?.image);
  const [tagIds, setTagIds] = useState<number[]>(data?.tagIds ?? []);
  const addTag = (tag?: TagDTO) => {
    if (tag?.id === undefined) return;
    setTagIds([...tagIds, tag.id]);
  };
  const removeTag = (tagId?: number) => {
    if (tagId === undefined) return;
    setTagIds(tagIds.filter((id) => id !== tagId));
  };

  const editableLocations = useEditableLocations();
  const editableTags = useEditableTags();

  const itemLocation = useLocationById(data?.locationId);

  const availableLocations = editableLocations.data
    ?.map((loc) => loc.id)
    .includes(itemLocation.data?.id)
    ? editableLocations.data
    : editableLocations.data?.concat(itemLocation.data ?? []);

  const {
    register,
    handleSubmit,
    reset,
    // formState: { errors }, TODO: add error handling
  } = useForm({ defaultValues: data });

  useEffect(() => {
    if (availableLocations) {
      reset();
    }
  }, [availableLocations?.length]);

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e === null) return;
    if (e.target === null) return;
    if (e.target.files === null) return;
    setImage(e.target.files[0]);
  };

  const submit = async (item: {
    name?: string;
    description?: string;
    slug?: string;
    enabled?: boolean;
    locationId?: number;
  }) => {
    await onSubmit({ ...item, tagIds, image });
  };

  const cancel = (e: React.FormEvent) => {
    e.preventDefault();
    onCancel();
  };

  return (
    <>
      <PickDialog
        title='Add tag'
        description='Choose a tag to add'
        options={
          editableTags?.data
            ?.filter((tag) => tag.id && !tagIds.includes(tag.id))
            ?.map((tag) => ({
              name: tag.name ?? 'Unknown tag',
              value: tag,
            })) ?? []
        }
        visible={isAddTagVisible}
        onClose={() => setAddTagVisible(false)}
        onPick={addTag}
      />
      <div className='my-4'>
        <Header text={title} type={HeaderType.SCREEN} className='mb-4' />
        <form onSubmit={handleSubmit(submit)}>
          <section className='pb-2'>
            <label className='block font-semibold' htmlFor='name'>
              Name
            </label>
            <input
              {...register('name', { required: true })}
              className='form-input border-gray-300'
              type='text'
            />
          </section>

          <section className='py-2'>
            <label className='inline-flex items-center w-full cursor-pointer'>
              <input
                {...register('enabled', {
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    setEnabled(e.target.checked),
                })}
                className='peer sr-only'
                type='checkbox'
              />
              <div className='mr-4 flex-grow inline-block'>
                <p className='font-semibold'>
                  {enabled ? 'Reservations enabled' : 'Reservations disabled'}
                </p>
                <p className='text-sm text-gray-700'>
                  This indicates whether users can see this item and reserve it.
                </p>
              </div>
              <div className='flex-shrink-0 form-checkbox-toggle' />
            </label>
          </section>

          <section className='py-2'>
            <label>
              <p className='font-semibold'>Description</p>
              <textarea className='form-input' {...register('description')} />
            </label>
          </section>

          <section className='py-2'>
            <label htmlFor='slug' className='font-semibold'>
              Slug
            </label>
            <input
              {...register('slug', { required: true })}
              className='form-input border-gray-300'
              type='text'
            />
          </section>

          <section className='py-2'>
            <label htmlFor='locationId' className='font-semibold'>
              Location
            </label>
            <select
              {...register('locationId', {
                valueAsNumber: true,
              })}
              className='form-input border-gray-300'
            >
              {availableLocations?.map((loc) => (
                <option key={loc.id} value={loc.id}>
                  {loc.name}
                </option>
              ))}
            </select>
          </section>

          <section className='py-2'>
            <div className='sm:flex'>
              <p className='flex-grow font-semibold'>Tags</p>
              <Button
                color={ButtonColor.PRIMARY_LIGHT}
                type={ButtonType.DEFAULT}
                icon={mdiPlus}
                onClick={(e) => {
                  e.preventDefault();
                  setAddTagVisible(true);
                }}
                className='mt-4 sm:mt-0'
              >
                Add
              </Button>
            </div>
            {tagIds.length === 0 ? (
              <p className='text-gray-700'>No tags</p>
            ) : (
              <div className='flex flex-row flex-wrap items-start gap-x-4 gap-y-2'>
                {tagIds.map((tagId) => (
                  <Tag
                    key={tagId}
                    tagId={tagId}
                    onDelete={() => removeTag(tagId)}
                  />
                ))}
              </div>
            )}
          </section>

          <section className='py-2'>
            <p className='font-semibold'>Image</p>
            <div className='py-2'>
              <label className='block cursor-pointer bg-gray-100 hover:bg-gray-300 rounded-lg border border-gray-300 overflow-hidden'>
                <img
                  className={cx(
                    'w-full object-scale-down block border-gray-300 rounded-t-lg',
                    {
                      'h-16 p-1': image !== undefined,
                      'h-0': image === undefined,
                    }
                  )}
                  src={image ? URL.createObjectURL(image) : undefined}
                  alt={'Uploaded item image'}
                />
                <p className='mx-auto text-center p-2'>
                  <Icon
                    path={mdiUpload}
                    className='w-4 h-4 mr-1 inline-block align-sub'
                  />
                  {image ? 'Replace image' : 'Upload image'}
                </p>
                <input
                  type='file'
                  onChange={onImageChange}
                  className='sr-only'
                />
              </label>
            </div>
          </section>

          <section className='py-4'>
            <Button
              color={ButtonColor.PRIMARY_DARK}
              type={ButtonType.DEFAULT}
              icon={mdiContentSave}
              className='mr-2'
            >
              Save
            </Button>
            <Button
              color={ButtonColor.WHITE}
              type={ButtonType.DEFAULT}
              icon={mdiClose}
              onClick={cancel}
            >
              Cancel
            </Button>
          </section>
        </form>
      </div>
    </>
  );
};
