import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { NotFoundMessage } from '~src/components/sections/NotFoundMessage';
import { routes } from '~src/routes';
import { useOAuth } from '~src/state/auth';
import { showToast } from '~src/utils/toast';
import { LoggingInScreen } from './LoggingInScreen';

const SUPPORTED_OAUTH_TYPES = ['is'];

export const OAuthScreen = () => {
  const urlParams = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const oauthLogin = useOAuth();

  const oauthCode = searchParams.get('code') ?? undefined;
  const oauthState = searchParams.get('state') ?? undefined;
  const oauthError = searchParams.get('error') ?? undefined;

  useEffect(() => {
    if (oauthCode === undefined || oauthState === undefined) {
      if (oauthError === 'access_denied') {
        showToast.error('Could not log in, IS access was denied');
      } else {
        showToast.error('Could not log in');
      }
      navigate(routes.main);
      return;
    }

    oauthLogin(oauthCode, oauthState)
      .catch(() => {
        showToast.error('An error occurred while logging in');
      })
      .finally(() => {
        navigate(routes.main);
      });
  }, [searchParams]);

  if (
    urlParams.oauthType === undefined ||
    !SUPPORTED_OAUTH_TYPES.includes(urlParams.oauthType)
  ) {
    return <NotFoundMessage />;
  }

  return <LoggingInScreen />;
};
