import { ClassNameProps } from '~src/utils/ClassNameProps';

export enum HeaderType {
  SCREEN,
  SECTION,
  SUBSECTION,
}

export interface HeaderProps extends ClassNameProps {
  text: string;
  type: HeaderType;
  count?: number;
}

export const Header = (props: HeaderProps) => {
  let textSize;
  switch (props.type) {
    case HeaderType.SCREEN:
      textSize = 'text-3xl';
      break;
    case HeaderType.SECTION:
      textSize = 'text-2xl';
      break;
    case HeaderType.SUBSECTION:
    default:
      textSize = 'text-xl';
      break;
  }

  return (
    <header className={props.className}>
      <h1>
        <span className={`font-display ${textSize} font-semibold`}>
          {props.text}
        </span>
        <span className='text-center ml-2 inline-block align-text-bottom'>
          {props.count}
        </span>
      </h1>
    </header>
  );
};
