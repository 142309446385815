import { mdiDelete, mdiPencil } from '@mdi/js';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, PageLink } from 'src/components/icons/Breadcrumbs';
import { NotFoundMessage } from 'src/components/sections/NotFoundMessage';
import { routes } from 'src/routes';
import {
  useDeleteLocation,
  useEditableLocations,
  useItemsByLocation,
  useLocationBySlug,
  useParentLocations,
  useUpdateLocation,
} from 'src/state/locations';
import {
  Button,
  ButtonColor,
  ButtonType,
} from '~src/components/controls/Button';
import { DeleteDialog } from '~src/components/sections/DeleteDialog';
import { Header, HeaderType } from '~src/components/sections/Header';
import {
  EditLocationData,
  EditLocationScreen,
} from '~src/screens/manage/location/EditLocationScreen';
import { useImageById } from '~src/state/images';
import { useIsAdmin } from '~src/state/user';
import { showToast } from '~src/utils/toast';
import { LocationItemList } from './components/LocationItemList';

export const LocationScreen = () => {
  const urlParams = useParams();
  const navigate = useNavigate();

  const location = useLocationBySlug(urlParams.locationSlug);
  const locationItems = useItemsByLocation(location?.data);
  const image = useImageById(location?.data?.imageId);
  const parentLocations = useParentLocations(location?.data?.id);

  const editableLocations = useEditableLocations();
  const isUserAdmin = useIsAdmin();

  const updateLocation = useUpdateLocation();
  const deleteLocation = useDeleteLocation();

  const [isEditVisible, setEditVisible] = useState<boolean>(false);
  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);

  if (urlParams.locationSlug === undefined) {
    return <NotFoundMessage />;
  }
  if (location?.error) {
    return <NotFoundMessage />;
  }

  if (isEditVisible) {
    return (
      <EditLocationScreen
        data={{ ...location.data }}
        title='Edit location'
        onSubmit={async (item: EditLocationData) => {
          if (!location?.data?.id) return;
          await updateLocation
            .mutateAsync({
              id: location.data.id,
              location: {
                name: item.name,
                description: item.description,
                slug: item.slug,
                parentLocationId: item.parentLocationId,
              },
              image: item.image,
            })
            .then(() => {
              showToast.success('Location updated successfully');
              setEditVisible(false);
              // handle slug change
              if (item.slug !== location.data?.slug) {
                navigate(routes.locationBySlug(item.slug));
              }
            })
            .catch(() => {
              showToast.error('Could not update location');
            });
        }}
        onCancel={() => setEditVisible(false)}
      />
    );
  }

  const content = () => {
    if (locationItems.error) return <p>Error</p>;
    if (locationItems.data) {
      return (
        <>
          {locationItems.data.map((item, index) => (
            <LocationItemList
              key={item.name}
              items={item}
              isRoot={index === 0}
            />
          ))}
        </>
      );
    }
  };

  const locationName = location.data?.name ?? 'Location';

  return (
    <>
      <DeleteDialog
        title='Delete location'
        description='This will delete the location!'
        text='Are you sure you want to delete this location?'
        visible={isDeleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDelete={() => {
          if (location?.data?.id === undefined) return;
          deleteLocation
            .mutateAsync(location?.data?.id)
            .then(() => {
              showToast.success('Location deleted successfully');
              navigate(routes.browse);
            })
            .catch(() => {
              showToast.error('Could not delete location');
            });
        }}
      />
      <div className='mt-4'>
        <Breadcrumbs
          links={[
            {
              name: 'Browse',
              route: routes.browse,
            },
            ...(parentLocations.data
              ?.map(
                (location) =>
                  ({
                    name: location.name,
                    route: routes.locationBySlug(location.slug),
                  } as PageLink)
              )
              .reverse() ?? []),
          ]}
        />
        <section className='my-4'>
          <div>
            {
              /* TODO: fix once images work on BE */
              false && image.data !== undefined && (
                <img
                  src={image.data}
                  alt={location.data?.name}
                  className='w-8 h-8 mr-4 inline-block align-middle'
                />
              )
            }
            <Header
              text={locationName}
              type={HeaderType.SCREEN}
              className='inline-block align-middle'
            />
          </div>
          <p className='my-4'>{location.data?.description}</p>
          {(editableLocations.data
            ?.map((loc) => loc.id)
            .includes(location.data?.id) ||
            isUserAdmin.data === true) && (
            <div>
              <Button
                color={ButtonColor.WHITE}
                type={ButtonType.DEFAULT}
                icon={mdiPencil}
                onClick={() => setEditVisible(true)}
                className='mr-4'
              >
                Edit
              </Button>
              <Button
                color={ButtonColor.ERROR}
                type={ButtonType.DEFAULT}
                icon={mdiDelete}
                onClick={() => setDeleteOpen(true)}
              >
                Delete
              </Button>
            </div>
          )}
        </section>
        <div className='mt-8'>{content()}</div>
      </div>
    </>
  );
};
