import { useManagedItemIds } from '~src/state/items';
import { Favorites } from './components/Favorites';
import { UnconfirmedList } from './components/UnconfirmedList';
import { UpcomingList } from './components/UpcomingList';

export const OverviewScreen = () => {
  const { data: managedItemIds } = useManagedItemIds();
  const managesItems = (managedItemIds?.length ?? 0) > 0;

  return (
    <>
      <h1 className='text-3xl font-display font-semibold my-4'>Overview</h1>
      <Favorites />
      {managesItems && <UnconfirmedList />}
      <UpcomingList />
    </>
  );
};
