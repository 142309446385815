import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonColor,
  ButtonType,
} from 'src/components/controls/Button';
import { routes } from 'src/routes';
const pageNotFound = new URL(
  '~/src/assets/undraw_page_not_found.svg',
  import.meta.url
).toString();

export const NotFoundMessage = () => {
  const navigate = useNavigate();

  return (
    <div className='mx-auto my-8 text-center'>
      <h1 className='font-display text-2xl font-semibold'>Page not found</h1>
      <img
        src={pageNotFound}
        alt='"Page not found" illustration'
        className='h-64 mx-auto pt-8'
      />
      <Button
        color={ButtonColor.PRIMARY_DARK}
        type={ButtonType.DEFAULT}
        onClick={() => navigate(routes.main)}
        className='mt-8'
      >
        Back to home
      </Button>
    </div>
  );
};
