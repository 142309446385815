/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReservationCreateDTO } from '../models/ReservationCreateDTO';
import type { ReservationDTO } from '../models/ReservationDTO';
import type { ReservationUpdateDTO } from '../models/ReservationUpdateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReservationControllerService {

    /**
     * @param id
     * @returns ReservationDTO OK
     * @throws ApiError
     */
    public static findReservationById(
        id: number,
    ): CancelablePromise<ReservationDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/reservation/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ReservationDTO OK
     * @throws ApiError
     */
    public static updateReservation(
        id: number,
        requestBody: ReservationUpdateDTO,
    ): CancelablePromise<ReservationDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/reservation/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ReservationDTO Created
     * @throws ApiError
     */
    public static saveReservation(
        requestBody: ReservationCreateDTO,
    ): CancelablePromise<ReservationDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/reservation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReservationDTO OK
     * @throws ApiError
     */
    public static allReservations(): CancelablePromise<Array<ReservationDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/',
        });
    }

    /**
     * @param id
     * @returns ReservationDTO OK
     * @throws ApiError
     */
    public static unconfirmedReservationsForUser(
        id: number,
    ): CancelablePromise<Array<ReservationDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/{id}/reservation/waiting',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ReservationDTO OK
     * @throws ApiError
     */
    public static upcomingUserReservations(
        id: number,
    ): CancelablePromise<Array<ReservationDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/{id}/reservation/upcoming',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ReservationDTO OK
     * @throws ApiError
     */
    public static findReservationByUserId(
        id: number,
    ): CancelablePromise<Array<ReservationDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/reservation/user/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ReservationDTO OK
     * @throws ApiError
     */
    public static findReservationByItemId(
        id: number,
    ): CancelablePromise<Array<ReservationDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/reservation/item/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ReservationDTO OK
     * @throws ApiError
     */
    public static upcomingItemReservations(
        id: number,
    ): CancelablePromise<Array<ReservationDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/item/{id}/reservation/upcoming',
            path: {
                'id': id,
            },
        });
    }

}