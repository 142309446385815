import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import cx from 'classnames';

export interface ChipProps {
  text?: string;
  onClick?: () => void;
  onDelete?: () => void;
}

export const Chip = ({ text, onClick, onDelete }: ChipProps) => {
  return (
    <p
      className={cx(
        'inline-block bg-accent-300 text-accent-900 rounded-lg px-4 py-1 select-none',
        {
          'cursor-pointer': onClick !== undefined,
        }
      )}
      onClick={onClick}
    >
      {text}
      {onDelete && (
        <span onClick={onDelete}>
          <Icon
            path={mdiClose}
            className='inline-block ml-2 h-4 w-4 cursor-pointer align-sub'
          />
        </span>
      )}
    </p>
  );
};
