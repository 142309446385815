import { mdiLockOpen, mdiLockOutline } from '@mdi/js';
import Icon from '@mdi/react';
import cx from 'classnames';
import { ClassNameProps } from '~src/utils/ClassNameProps';

export interface RoleAccessIndicatorProps extends ClassNameProps {
  isUnlimitedAccess?: boolean;
}

export const RoleAccessIndicator = ({
  isUnlimitedAccess,
  className,
}: RoleAccessIndicatorProps) => {
  const settings = isUnlimitedAccess
    ? {
        icon: mdiLockOpen,
        text: 'Unlimited access',
        className: 'text-warning-900',
      }
    : { icon: mdiLockOutline, text: 'Limited access' };

  return (
    <p className={cx(settings.className, className)}>
      <Icon
        path={settings.icon}
        size='1em'
        className='inline-block mr-2 align-sub'
      />
      <span>{settings.text}</span>
    </p>
  );
};
