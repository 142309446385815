import { useNavigate } from 'react-router-dom';
import { UserCard } from '~src/components/cards/UserCard';
import { UserDTO } from '~src/generated/api';
import { routes } from '~src/routes';

export const UserList = ({ users }: { users: UserDTO[] }) => {
  const navigate = useNavigate();

  return (
    <div className='rounded-lg'>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
        {users.map((user) => (
          <UserCard
            key={user.id}
            user={user}
            onClick={() => navigate(routes.userById(user.id))}
          />
        ))}
      </div>
    </div>
  );
};
