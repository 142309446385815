import { Tab } from '@headlessui/react';
import Icon from '@mdi/react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Header, HeaderType } from '~src/components/sections/Header';
import { routes } from '~src/routes';
import { useIsAdmin } from '~src/state/user';
import { MANAGE_TABS } from './manageTabs';

export const ManageScreen = ({ tab = 'items' }: { tab?: string }) => {
  const navigate = useNavigate();
  const onTabIndexChange = (index: number) => {
    navigate(`${routes.manage}/${MANAGE_TABS[index].id}`);
  };

  const isUserAdmin = useIsAdmin();

  const displayedTabs = isUserAdmin.data
    ? MANAGE_TABS
    : MANAGE_TABS.filter((tab) => !tab.adminOnly);

  const defaultId = tab ?? 'items';

  return (
    <div className='my-4'>
      <Header type={HeaderType.SCREEN} text='Manage' className='mb-4' />
      <Tab.Group
        onChange={onTabIndexChange}
        defaultIndex={displayedTabs.findIndex((tab) => tab.id === defaultId)}
      >
        <Tab.List className='flex flex-col sm:flex-row items-start gap-2 border-b pb-4 sm:pb-0 border-gray-300'>
          {displayedTabs.map((tab) => (
            <Tab
              key={tab.id}
              className={({ selected }) =>
                cx(
                  'flex flex-wrap items-center gap-2 px-4 py-2 transition-all rounded-b-lg sm:rounded-b-none rounded-t-lg',
                  {
                    'bg-primary-300 text-primary-900': selected,
                    'text-gray-900 hover:bg-gray-300': !selected,
                  }
                )
              }
            >
              <Icon
                path={tab.icon}
                className='inline-block h-6 w-6 align-middle mx-auto'
              />
              <span className='inline-block h-6 align-middle mx-auto'>
                {tab.name}
              </span>
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {displayedTabs.map((tab) => (
            <Tab.Panel key={tab.name}>{tab.content}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
