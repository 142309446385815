import { mdiCheck, mdiClose } from '@mdi/js';
import { useAtomValue } from 'jotai';
import { useForm } from 'react-hook-form';
import {
  Button,
  ButtonColor,
  ButtonType,
} from 'src/components/controls/Button';
import { ReservationCreateDTO } from 'src/generated/api';
import { currentUserIdAtom } from 'src/state/auth';
import { useCreateReservation } from 'src/state/reservations';
import { showToast } from 'src/utils/toast';
import { CustomDialog } from '~src/components/sections/CustomDialog';

export interface ReserveDialogProps {
  itemId: number;
  visible: boolean;
  onClose: () => void;
}

export const ReserveDialog = (props: ReserveDialogProps) => {
  const {
    register,
    handleSubmit,
    // formState: { errors }, TODO: add error handling
  } = useForm();

  const createReservation = useCreateReservation();
  const currentUserId = useAtomValue(currentUserIdAtom);

  const submitReservation = (data: ReservationCreateDTO) => {
    createReservation
      .mutateAsync({
        startTime: data.startTime?.replace('T', ' ')?.concat(':00'),
        endTime: data.endTime?.replace('T', ' ')?.concat(':00'),
        itemId: props.itemId,
        userId: currentUserId,
      })
      .then(() => {
        showToast.success('Reservation created successfully');
        props.onClose();
      })
      .catch(() => {
        showToast.error('Could not create reservation');
      });
  };

  return (
    <CustomDialog
      title={'Create reservation'}
      description={
        'Reserve an item for a specified time. After reserving, please wait for the reservation to get confirmed.'
      }
      visible={props.visible}
      onClose={props.onClose}
    >
      <form onSubmit={handleSubmit(submitReservation)}>
        <div className='my-2'>
          <label className='block font-semibold' htmlFor='startTime'>
            Start time
          </label>
          <input
            {...register('startTime', { required: true })}
            className='form-input border-gray-300'
            type='datetime-local'
          />
        </div>

        <div className='my-2'>
          <label className='block font-semibold' htmlFor='endTime'>
            End time
          </label>
          <input
            {...register('endTime', { required: true })}
            className='form-input border-gray-300'
            type='datetime-local'
          />
        </div>

        <div className='mt-2 flex flex-row gap-4'>
          <Button
            color={ButtonColor.PRIMARY_LIGHT}
            type={ButtonType.DEFAULT}
            icon={mdiCheck}
            className='flex-grow'
          >
            Reserve
          </Button>
          <Button
            color={ButtonColor.WHITE}
            type={ButtonType.DEFAULT}
            icon={mdiClose}
            onClick={(e) => {
              e.preventDefault();
              props.onClose();
            }}
            className='flex-grow'
          >
            Cancel
          </Button>
        </div>
      </form>
    </CustomDialog>
  );
};
