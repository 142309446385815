import { Dialog, Transition } from '@headlessui/react';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Fragment, ReactNode } from 'react';

export interface CustomDialogProps {
  title: string;
  description: ReactNode | ReactNode[];
  visible: boolean;
  onClose: () => void | Promise<void>;
  children: ReactNode | ReactNode[];
}

export const CustomDialog = ({
  title,
  description,
  visible,
  onClose,
  children,
}: CustomDialogProps) => {
  return (
    <Transition as={Fragment} show={visible}>
      <Dialog as='div' className='z-50' onClose={onClose}>
        <Transition.Child
          enter='ease-out duration-200'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-900 opacity-50' />
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-200'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='bg-gray-100 max-w-md m-auto p-6 rounded-lg shadow-mini'>
                <div className='flex flex-row items-center mb-4'>
                  <Dialog.Title className='text-2xl font-display font-semibold flex-grow'>
                    {title}
                  </Dialog.Title>
                  <div onClick={onClose}>
                    <Icon path={mdiClose} className='w-6 h-6 cursor-pointer' />
                  </div>
                </div>
                <Dialog.Description>{description}</Dialog.Description>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
