/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BanCreateDTO } from '../models/BanCreateDTO';
import type { UserDTO } from '../models/UserDTO';
import type { UserUpdateDTO } from '../models/UserUpdateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserControllerService {

    /**
     * @param id
     * @returns UserDTO OK
     * @throws ApiError
     */
    public static findUserById(
        id: number,
    ): CancelablePromise<UserDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns UserDTO OK
     * @throws ApiError
     */
    public static updateUser(
        id: number,
        requestBody: UserUpdateDTO,
    ): CancelablePromise<UserDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/user/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteUser(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param userId
     * @param itemId
     * @returns UserDTO Created
     * @throws ApiError
     */
    public static addFavouriteItem(
        userId: number,
        itemId: number,
    ): CancelablePromise<UserDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/{userId}/favourite/{itemId}',
            path: {
                'userId': userId,
                'itemId': itemId,
            },
        });
    }

    /**
     * @param userId
     * @param itemId
     * @returns UserDTO OK
     * @throws ApiError
     */
    public static deleteFavouriteItem(
        userId: number,
        itemId: number,
    ): CancelablePromise<UserDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/{userId}/favourite/{itemId}',
            path: {
                'userId': userId,
                'itemId': itemId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static banUser(
        requestBody: BanCreateDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/ban',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserDTO OK
     * @throws ApiError
     */
    public static allUsers(): CancelablePromise<Array<UserDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user',
        });
    }

}