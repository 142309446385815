import { Breadcrumbs } from '~src/components/icons/Breadcrumbs';
import { LocationList } from '~src/components/sections/LocationList';
import { Header, HeaderType } from '~src/components/sections/Header';

export const BrowseScreen = () => {
  return (
    <div className='my-4'>
      <Breadcrumbs
        links={[
          {
            name: 'Browse',
          },
        ]}
      />
      <Header text='Locations' type={HeaderType.SCREEN} className='my-4' />
      <LocationList />
    </div>
  );
};
