import { mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';
import cx from 'classnames';
import { MouseEvent, ReactNode, useState } from 'react';
import { ClassNameProps } from '~src/utils/ClassNameProps';
import { useMounted } from '~src/utils/useMounted';

export enum ButtonColor {
  ERROR,
  PRIMARY_DARK,
  PRIMARY_LIGHT,
  PRIMARY_LIGHTER,
  WHITE,
}

export enum ButtonType {
  DEFAULT,
  SMALL,
  FLAT,
}

export interface ButtonProps extends ClassNameProps {
  color: ButtonColor;
  type: ButtonType;
  disabled?: boolean;
  icon?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>;
  children?: ReactNode;
}

export const Button = (props: ButtonProps) => {
  const colorToStyle = {
    [ButtonColor.ERROR]: 'bg-error-100 hover:bg-error-300 text-error-900',
    [ButtonColor.PRIMARY_DARK]:
      'bg-primary-700 hover:bg-primary-900 text-primary-100',
    [ButtonColor.PRIMARY_LIGHT]:
      'bg-primary-300 hover:bg-primary-500 text-primary-900',
    [ButtonColor.PRIMARY_LIGHTER]:
      'border border-primary-900 hover:bg-primary-300 text-primary-900',
    [ButtonColor.WHITE]: 'bg-white hover:bg-gray-300 text-gray-900',
  };

  const typeToStyle = {
    [ButtonType.DEFAULT]: 'text-base px-4 py-1 rounded-lg shadow-mini',
    [ButtonType.SMALL]: 'text-sm px-2 py-1 rounded-lg shadow-mini',
    [ButtonType.FLAT]: 'text-base px-4 py-2',
  };

  const [loading, setLoading] = useState(false);
  const icon = loading ? mdiLoading : props.icon;

  const mounted = useMounted();

  const onClick = async (e: MouseEvent<HTMLButtonElement>) => {
    if (props.onClick === undefined) return;
    setLoading(true);
    await props.onClick(e);
    if (mounted.current) {
      setLoading(false);
    }
  };

  return (
    <button
      className={cx(
        'transition align-baseline',
        colorToStyle[props.color],
        typeToStyle[props.type],
        props.className
      )}
      onClick={onClick}
      disabled={props.disabled || loading}
    >
      {icon && (
        <Icon
          className='inline-block mr-1 align-middle'
          path={icon}
          spin={loading}
          size={props.type === ButtonType.SMALL ? '0.875rem' : '1rem'}
        />
      )}
      <div className={cx({ 'inline-block align-middle': icon })}>
        {props.children}
      </div>
    </button>
  );
};
