import {
  mdiCalendar,
  mdiDelete,
  mdiHeart,
  mdiHeartOutline,
  mdiPencil,
} from '@mdi/js';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  ButtonColor,
  ButtonType,
} from 'src/components/controls/Button';
import { Breadcrumbs, PageLink } from 'src/components/icons/Breadcrumbs';
import { Header, HeaderType } from 'src/components/sections/Header';
import { NotFoundMessage } from 'src/components/sections/NotFoundMessage';
import { ReservationList } from 'src/components/sections/ReservationList';
import { routes } from 'src/routes';
import {
  useEditableItems,
  useFavoriteItems,
  useItemBySlug,
  useUpdateItem,
  useUserItemActions,
} from 'src/state/items';
import { useParentLocations } from 'src/state/locations';
import { useUpcomingItemReservations } from 'src/state/reservations';
import { DeleteDialog } from '~src/components/sections/DeleteDialog';
import { TagList } from '~src/components/sections/TagList';
import {
  EditItemData,
  EditItemScreen,
} from '~src/screens/manage/item/EditItemScreen';
import { showToast } from '~src/utils/toast';
import { ReserveDialog } from './components/ReserveDialog';

export const ItemScreen = () => {
  const urlParams = useParams();
  const navigate = useNavigate();

  if (urlParams.itemSlug === undefined) {
    return <NotFoundMessage />;
  }

  const [isReserveOpen, setReserveOpen] = useState<boolean>(false);
  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [isEditVisible, setEditVisible] = useState<boolean>(false);

  const { error, data: item } = useItemBySlug(urlParams.itemSlug);
  const parentLocations = useParentLocations(item?.locationId);
  const reservationList = useUpcomingItemReservations(item?.id);

  const editableItems = useEditableItems();

  const userFavorites = useFavoriteItems();
  const itemActions = useUserItemActions();
  const updateItem = useUpdateItem();

  const [isFavorite, setFavorite] = useState<boolean>(false);
  useEffect(() => {
    setFavorite(
      userFavorites.data?.map((item) => item.id).includes(item?.id) ?? false
    );
  }, [userFavorites.data, item]);

  if (error) {
    return <NotFoundMessage />;
  }

  if (isEditVisible) {
    return (
      <EditItemScreen
        data={item}
        title='Edit item'
        onSubmit={async (newItem: EditItemData) => {
          if (!item?.id) return;
          const image = newItem.image;
          delete newItem.image;

          await updateItem
            .mutateAsync({
              id: item.id,
              item: newItem,
              image,
            })
            .then(() => {
              showToast.success('Item updated successfully');
              setEditVisible(false);
              // handle slug change
              if (item.slug !== newItem.slug) {
                navigate(routes.itemBySlug(item.slug));
              }
            })
            .catch(() => {
              showToast.error('Could not update item');
            });
        }}
        onCancel={() => setEditVisible(false)}
      />
    );
  }

  const handleFavorite = () => {
    if (isFavorite) {
      itemActions.removeFavorite.mutate(item?.id);
    } else {
      itemActions.addFavorite.mutate(item?.id);
    }
    setFavorite(!isFavorite);
  };

  const itemName = item?.name ?? 'Item';

  return (
    <>
      <DeleteDialog
        title='Delete item'
        description='This will delete the item!'
        text='Are you sure you want to delete this item?'
        visible={isDeleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDelete={() => {
          itemActions.deleteItem
            .mutateAsync(item?.id)
            .then(() => {
              showToast.success('Item deleted successfully');
              navigate(routes.browse);
            })
            .catch(() => {
              showToast.error('Could not delete item');
            });
        }}
      />
      {item?.id && (
        <ReserveDialog
          itemId={item.id}
          visible={isReserveOpen}
          onClose={() => setReserveOpen(false)}
        />
      )}
      <div className='my-4'>
        <Breadcrumbs
          links={[
            { name: 'Browse', route: routes.browse },
            ...(parentLocations.data
              ?.map(
                (location) =>
                  ({
                    name: location.name,
                    route: routes.locationBySlug(location.slug),
                  } as PageLink)
              )
              .reverse() ?? []),
            { name: itemName },
          ]}
        />
        <section className='mt-4 mb-8'>
          <div className='sm:flex'>
            <div className='flex-grow'>
              <Header text={itemName} type={HeaderType.SCREEN} />
              <TagList tagIds={item?.tagIds ?? []} />
              <p className='my-4'>{item?.description}</p>
            </div>
            <img
              src=''
              alt={item?.name}
              className='block w-32 object-cover rounded-md mb-4 bg-gray-500 border border-gray-500'
            />
          </div>
          <div className='flex flex-wrap flex-row gap-x-4 gap-y-2'>
            <Button
              icon={mdiCalendar}
              color={ButtonColor.PRIMARY_DARK}
              type={ButtonType.DEFAULT}
              onClick={() => setReserveOpen(true)}
            >
              Reserve
            </Button>
            <Button
              icon={isFavorite ? mdiHeart : mdiHeartOutline}
              color={ButtonColor.WHITE}
              type={ButtonType.DEFAULT}
              onClick={handleFavorite}
            >
              {isFavorite ? 'Remove from favorites' : 'Add to favorites'}
            </Button>
            {/* TODO: Waiting for backend implementation
            <Button
              icon={mdiMessageTextOutline}
              color={ButtonColor.WHITE}
              size={ButtonType.DEFAULT}
            >
              Contact manager
            </Button> */}
            {editableItems.data?.map((item) => item.id)?.includes(item?.id) && (
              <>
                <Button
                  icon={mdiPencil}
                  color={ButtonColor.WHITE}
                  type={ButtonType.DEFAULT}
                  onClick={() => setEditVisible(true)}
                >
                  Edit
                </Button>
                <Button
                  icon={mdiDelete}
                  color={ButtonColor.ERROR}
                  type={ButtonType.DEFAULT}
                  onClick={() => setDeleteOpen(true)}
                >
                  Delete
                </Button>
              </>
            )}
          </div>
        </section>
        <section>
          {reservationList.error && (
            <p>Could not display upcoming reservations.</p>
          )}
          {reservationList.data &&
            (reservationList.data.length === 0 ? (
              <Header
                text='No upcoming reservations'
                type={HeaderType.SUBSECTION}
              />
            ) : (
              <>
                <Header
                  text='Upcoming reservations'
                  type={HeaderType.SECTION}
                  count={reservationList.data.length}
                  className='mb-4'
                />
                <ReservationList
                  reservations={reservationList.data}
                  showReservee={true}
                  sorted
                  noImage
                />
              </>
            ))}
        </section>
      </div>
    </>
  );
};
