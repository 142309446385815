import {
  mdiAccount,
  mdiCancel,
  mdiCheck,
  mdiClockOutline,
  mdiClose,
} from '@mdi/js';
import Icon from '@mdi/react';
import cx from 'classnames';
import { useAtomValue } from 'jotai';
import {
  Button,
  ButtonColor,
  ButtonType,
} from 'src/components/controls/Button';
import { ReservationIndicator } from 'src/components/icons/ReservationIndicator';
import { ItemDTO, LocationDTO, ReservationDTO } from 'src/generated/api';
import { timestampToString, upcomingTimeString } from 'src/utils/strings';
import { currentUserIdAtom } from '~src/state/auth';
import { useManagedItemIds } from '~src/state/items';
import { ClassNameProps } from '~src/utils/ClassNameProps';
import { Card } from './Card';

export interface ReservationCardProps extends ClassNameProps {
  reservation: ReservationDTO;
  locations: LocationDTO[];
  item: ItemDTO;
  reserveeName?: string;
  noImage?: boolean;
  onConfirm?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  onDeny?: () => void | Promise<void>;
  onItemNameClick?: () => void | Promise<void>;
}

export const ReservationCard = (props: ReservationCardProps) => {
  const currentUserId = useAtomValue(currentUserIdAtom);
  const managedItemIds = useManagedItemIds();

  const canChangeState =
    props.item.id !== undefined && managedItemIds.data?.includes(props.item.id);

  return (
    <Card className={cx('flex flex-col justify-between', props.className)}>
      {props.noImage !== true && (
        <img
          className='h-32 w-full object-cover bg-gray-500 border border-gray-500'
          src=''
          alt={props.item.name}
        />
      )}
      <div className='px-4 pt-4 flex-grow w-full'>
        <div className='pb-2'>
          <h1
            className={cx('font-semibold inline-block align-baseline mr-1', {
              'cursor-pointer hover:text-gray-700':
                props.onItemNameClick !== undefined,
            })}
            onClick={props.onItemNameClick}
          >
            {props.item.name ?? ''}
          </h1>
          <h2 className='text-sm italic inline-block align-baseline text-gray-700'>
            in {props.locations.map((loc) => loc.name).join(' / ')}
          </h2>
        </div>
        {props.reserveeName !== undefined &&
          props.reservation.userId !== undefined && (
            <div className='pb-2'>
              <Icon
                className='inline-block mr-1 align-middle'
                path={mdiAccount}
                title='Reservee'
                size='1rem'
              />
              <span className='align-middle inline-block'>
                {props.reserveeName}
              </span>
            </div>
          )}
        <div>
          <p>
            <Icon
              className='inline-block mr-1 align-middle'
              path={mdiClockOutline}
              title='Reservation Time'
              size='1rem'
            />
            <span className='align-middle inline-block'>
              {upcomingTimeString(props.reservation.startTime)}
            </span>
          </p>
        </div>
        <p className='text-sm pb-2'>
          {timestampToString(props.reservation.startTime) +
            ' - ' +
            timestampToString(props.reservation.endTime)}
        </p>
      </div>
      {props.reservation.state !== undefined && (
        <ReservationIndicator
          state={props.reservation.state}
          className='pb-4 px-4'
        />
      )}
      <div className='flex flex-wrap border-t border-gray-300'>
        {props.reservation.state === ReservationDTO.state.CREATED &&
          canChangeState && (
            <Button
              color={ButtonColor.PRIMARY_LIGHT}
              type={ButtonType.FLAT}
              icon={mdiCheck}
              onClick={props.onConfirm}
              className='flex-basis-0 flex-grow min-w-1/2'
            >
              Confirm
            </Button>
          )}
        {canChangeState && (
          <Button
            color={ButtonColor.WHITE}
            type={ButtonType.FLAT}
            icon={mdiCancel}
            onClick={props.onDeny}
            className='flex-basis-0 flex-grow min-w-1/2'
          >
            Deny
          </Button>
        )}
        {props.reservation.userId === currentUserId && (
          <Button
            color={ButtonColor.ERROR}
            type={ButtonType.FLAT}
            icon={mdiClose}
            onClick={props.onCancel}
            className='flex-basis-0 flex-grow min-w-1/2'
          >
            Cancel
          </Button>
        )}
      </div>
    </Card>
  );
};
