import { RoleDTO } from '~src/generated/api';
import { RoleAccessIndicator } from '../icons/RoleAccessIndicator';
import { Card } from './Card';

export const RoleCard = ({
  role,
  onClick,
}: {
  role: RoleDTO;
  onClick?: () => void;
}) => {
  return (
    <Card className='p-4' onClick={onClick}>
      <h1 className='font-semibold'>{role.name}</h1>
      <RoleAccessIndicator isUnlimitedAccess={role.admin} className='text-sm' />
    </Card>
  );
};
