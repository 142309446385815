import {
  mdiAccount,
  mdiBadgeAccountOutline,
  mdiCubeOutline,
  mdiMapMarker,
  mdiTagOutline,
} from '@mdi/js';
import { ManageLocationScreen } from '~src/screens/manage/location/ManageLocationScreen';
import { NewLocationScreen } from '~src/screens/manage/location/NewLocationScreen';
import { ManageItemScreen } from './item/ManageItemScreen';
import { NewItemScreen } from './item/NewItemScreen';
import { ManageRoleScreen } from './role/ManageRoleScreen';
import { NewRoleScreen } from './role/NewRoleScreen';
import { ManageTagScreen } from './tag/ManageTagScreen';
import { NewTagScreen } from './tag/NewTagScreen';
import { ManageUserScreen } from './user/ManageUserScreen';

export interface ManageTab {
  id: string;
  name: string;
  icon: string;
  content: JSX.Element;
  creationScreen?: JSX.Element;
  adminOnly: boolean;
}

export const MANAGE_TABS: ManageTab[] = [
  {
    id: 'items',
    name: 'Items',
    icon: mdiCubeOutline,
    content: <ManageItemScreen />,
    creationScreen: <NewItemScreen />,
    adminOnly: false,
  },
  {
    id: 'locations',
    name: 'Locations',
    icon: mdiMapMarker,
    content: <ManageLocationScreen />,
    creationScreen: <NewLocationScreen />,
    adminOnly: false,
  },
  {
    id: 'tags',
    name: 'Tags',
    icon: mdiTagOutline,
    content: <ManageTagScreen />,
    creationScreen: <NewTagScreen />,
    adminOnly: false,
  },
  {
    id: 'roles',
    name: 'Roles',
    icon: mdiBadgeAccountOutline,
    content: <ManageRoleScreen />,
    creationScreen: <NewRoleScreen />,
    adminOnly: false,
  },
  {
    id: 'users',
    name: 'Users',
    icon: mdiAccount,
    content: <ManageUserScreen />,
    adminOnly: true,
  },
];
