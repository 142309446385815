import { Loading } from '~src/components/icons/Loading';
import { ErrorMessage } from '~src/components/sections/ErrorMessage';
import { Header, HeaderType } from '~src/components/sections/Header';
import { useAllUsers } from '~src/state/user';
import { UserList } from './components/UserList';

export const ManageUserScreen = () => {
  const { error, isLoading, data: users } = useAllUsers();

  const getContent = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (error) {
      return <ErrorMessage />;
    }

    if (users === undefined || users.length === 0) {
      return <p>No users</p>;
    }

    return <UserList users={users} />;
  };

  return (
    <section className='py-4'>
      <Header text='Managed users' type={HeaderType.SECTION} className='my-4' />
      {getContent()}
    </section>
  );
};
