/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocationCreateDTO } from '../models/LocationCreateDTO';
import type { LocationDTO } from '../models/LocationDTO';
import type { LocationUpdateDTO } from '../models/LocationUpdateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LocationControllerService {

    /**
     * @param id
     * @returns LocationDTO OK
     * @throws ApiError
     */
    public static findLocationById(
        id: number,
    ): CancelablePromise<LocationDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param formData
     * @returns LocationDTO OK
     * @throws ApiError
     */
    public static updateLocation(
        id: number,
        formData?: {
            data: LocationUpdateDTO;
            image?: Blob;
        },
    ): CancelablePromise<LocationDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/location/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteLocation(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/location/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LocationDTO OK
     * @throws ApiError
     */
    public static allLocations(): CancelablePromise<Array<LocationDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location',
        });
    }

    /**
     * @param formData
     * @returns LocationDTO Created
     * @throws ApiError
     */
    public static saveLocation(
        formData?: {
            data: LocationCreateDTO;
            image?: Blob;
        },
    ): CancelablePromise<LocationDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/location',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id
     * @returns LocationDTO OK
     * @throws ApiError
     */
    public static allLocationParents(
        id: number,
    ): CancelablePromise<Array<LocationDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location/{id}/parent',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns LocationDTO OK
     * @throws ApiError
     */
    public static allLocationChildren(
        id: number,
    ): CancelablePromise<Array<LocationDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location/{id}/child',
            path: {
                'id': id,
            },
        });
    }

}