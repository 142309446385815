import { Toaster } from 'react-hot-toast';
import { Outlet } from 'react-router-dom';
import { TopBar } from 'src/components/controls/TopBar';
// import { ReactQueryDevtools } from 'react-query/devtools';

export interface BaseScreenProps {
  showTopBar: boolean;
}

export const BaseScreen = (props: BaseScreenProps) => {
  return (
    <>
      <Toaster
        position='top-center'
        containerStyle={{ top: props.showTopBar ? '5rem' : '1rem' }}
      />
      {props.showTopBar && <TopBar />}
      <main className='max-w-[960px] mx-8 sm:mx-16 lg:mx-32 xl:mx-auto'>
        <Outlet />
      </main>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </>
  );
};
