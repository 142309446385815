/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticateRequest } from '../models/AuthenticateRequest';
import type { JWTResponse } from '../models/JWTResponse';
import type { RefreshTokenHolder } from '../models/RefreshTokenHolder';
import type { UserRegistration } from '../models/UserRegistration';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthenticateControllerService {

    /**
     * @param token
     * @returns JWTResponse OK
     * @throws ApiError
     */
    public static getJwt(
        token: string,
    ): CancelablePromise<JWTResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/token',
            query: {
                'token': token,
            },
        });
    }

    /**
     * @param requestBody
     * @returns RefreshTokenHolder OK
     * @throws ApiError
     */
    public static register(
        requestBody: UserRegistration,
    ): CancelablePromise<RefreshTokenHolder> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/register',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns RefreshTokenHolder OK
     * @throws ApiError
     */
    public static auth(
        requestBody: AuthenticateRequest,
    ): CancelablePromise<RefreshTokenHolder> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/authenticate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param code
     * @param state
     * @returns RefreshTokenHolder OK
     * @throws ApiError
     */
    public static oauthLogin(
        code: string,
        state: string,
    ): CancelablePromise<RefreshTokenHolder> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/oauth/is',
            query: {
                'code': code,
                'state': state,
            },
        });
    }

}