import { mdiTrashCan } from '@mdi/js';
import { RoleDTO } from '~src/generated/api';
import { Button, ButtonColor, ButtonType } from '../controls/Button';
import { RoleAccessIndicator } from '../icons/RoleAccessIndicator';

export const RoleEditCard = ({
  role,
  onDelete,
}: {
  role: RoleDTO;
  onDelete: () => void;
}) => {
  return (
    <article className='border border-gray-500 rounded-lg flex items-center'>
      <div className='flex-grow p-4'>
        <h1 className='font-semibold'>{role.name}</h1>
        <RoleAccessIndicator
          isUnlimitedAccess={role.admin}
          className='text-sm'
        />
      </div>
      <Button
        color={ButtonColor.ERROR}
        type={ButtonType.DEFAULT}
        icon={mdiTrashCan}
        onClick={onDelete}
        className='h-12 w-12 m-2'
      />
    </article>
  );
};
