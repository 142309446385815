import cx from 'classnames';
import { ReactNode } from 'react';
import { ClassNameProps } from '~src/utils/ClassNameProps';

export interface CardProps extends ClassNameProps {
  children: ReactNode;
  onClick?: () => void;
}

export const Card = (props: CardProps) => {
  return (
    <article
      className={cx(
        'rounded-lg shadow-soft overflow-hidden',
        {
          'cursor-pointer transition-all': props.onClick !== undefined,
          'bg-white': !props.className?.includes('bg-'),
          'hover:bg-gray-200':
            !props.className?.includes('bg-') && props.onClick !== undefined,
        },
        props.className
      )}
      onClick={props.onClick}
    >
      {props.children}
    </article>
  );
};
