export const routes = {
  main: '/',
  components: '/components',

  register: '/register',
  oauth: '/oauth',

  browse: '/browse',

  location: '/location',
  locationBySlug: (slug?: string) =>
    slug !== undefined ? `${routes.location}/${slug}` : routes.location,

  item: '/item',
  itemBySlug: (slug?: string) =>
    slug !== undefined ? `${routes.item}/${slug}` : routes.item,

  tag: '/tag',
  tagById: (id?: number) =>
    id !== undefined ? `${routes.tag}/${id}` : routes.tag,

  manage: '/manage',

  role: '/role',
  roleById: (id?: number) =>
    id !== undefined ? `${routes.role}/${id}` : routes.role,

  user: '/user',
  userById: (id?: number) =>
    id !== undefined ? `${routes.user}/${id}` : routes.user,
};
