export interface FallbackIconProps {
  text: string;
}

export const FallbackIcon = (props: FallbackIconProps) => {
  const hue =
    props.text.split('').reduce((a, b) => {
      return a + b.charCodeAt(0) * 100;
    }, 0) % 360;

  const trigram = props.text
    .split(' ')
    .map((word) => word[0])
    .join('')
    .slice(0, 3);

  return (
    <div
      className='w-12 h-12 rounded-lg mx-4 my-2 text-center'
      style={{ backgroundColor: `hsl(${hue}, 80%, 80%)` }}
    >
      <span
        className='inline-block align-middle my-3 select-none font-display'
        style={{ color: `hsl(${hue}, 90%, 15%)` }}
      >
        {trigram}
      </span>
    </div>
  );
};
