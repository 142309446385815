import { mdiStarOutline } from '@mdi/js';
import Icon from '@mdi/react';
import cx from 'classnames';
import { ClassNameProps } from '~src/utils/ClassNameProps';

export const FavoritePlaceholder = (props: ClassNameProps) => {
  return (
    <div
      className={cx(
        'rounded-2xl bg-gray-300 flex items-center',
        props.className
      )}
    >
      <Icon path={mdiStarOutline} className='h-8 w-8 text-gray-500 mx-auto' />
    </div>
  );
};
