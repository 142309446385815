import { mdiCheck, mdiClose } from '@mdi/js';
import { useSetAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonColor,
  ButtonType,
} from 'src/components/controls/Button';
import { Header, HeaderType } from '~src/components/sections/Header';
import {
  RefreshTokenHolder,
  RoleDTO,
  UserRegistration,
} from '~src/generated/api';
import { routes } from '~src/routes';
import { refreshTokenAtom } from '~src/state/auth';
import { useCreateUser } from '~src/state/user';
import { showToast } from '~src/utils/toast';

export interface RegisterData {
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  active?: boolean;
  roles?: RoleDTO[];
}

export const RegisterScreen = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    // formState: { errors }, TODO: add error handling
  } = useForm();

  const createUser = useCreateUser();
  const setRefreshToken = useSetAtom(refreshTokenAtom);

  const submit = (user: {
    firstName?: string;
    lastName?: string;
    username?: string;
    email?: string;
    password?: string;
    matchingPassword?: string;
  }) => {
    if (Object.values(user).some((val) => val === undefined)) return;
    createUser
      .mutateAsync(user as UserRegistration)
      .then((refreshTokenHolder: RefreshTokenHolder) => {
        showToast.success('Registered successfully');
        if (refreshTokenHolder.token) {
          setRefreshToken(refreshTokenHolder.token);
        }
        navigate(routes.main);
      })
      .catch(() => {
        showToast.error('Could not register');
      });
  };

  const cancel = (e: React.FormEvent) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <div className='my-16'>
        <Header
          text='Create account'
          type={HeaderType.SCREEN}
          className='mb-4'
        />
        <form onSubmit={handleSubmit(submit)}>
          <section className='my-4'>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 my-4'>
              <label className='block font-semibold'>
                First name
                <input
                  {...register('firstName', { required: true })}
                  className='form-input border-gray-300'
                  type='text'
                />
              </label>
              <label className='block font-semibold'>
                Last name
                <input
                  {...register('lastName', { required: true })}
                  className='form-input border-gray-300'
                  type='text'
                />
              </label>
            </div>
            <label className='block font-semibold my-4'>
              Username
              <input
                {...register('username', { required: true })}
                className='form-input border-gray-300'
                type='text'
              />
            </label>
            <label className='block font-semibold my-4'>
              Email
              <input
                {...register('email', {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
                className='form-input border-gray-300'
                type='text'
              />
            </label>
            <label className='block font-semibold my-4'>
              Password
              <input
                {...register('password', { required: true })}
                className='form-input border-gray-300'
                type='password'
              />
            </label>
            <label className='block font-semibold my-4'>
              Confirm password
              <input
                {...register('matchingPassword', { required: true })}
                className='form-input border-gray-300'
                type='password'
              />
            </label>
          </section>

          <section className='my-4'>
            <Button
              color={ButtonColor.PRIMARY_DARK}
              type={ButtonType.DEFAULT}
              icon={mdiCheck}
              className='mr-2'
            >
              Create
            </Button>
            <Button
              color={ButtonColor.WHITE}
              type={ButtonType.DEFAULT}
              icon={mdiClose}
              onClick={cancel}
            >
              Cancel
            </Button>
          </section>
        </form>
      </div>
    </>
  );
};
