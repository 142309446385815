import { authTokenPayloadAtom } from 'src/state/auth';
import { useAtomValue } from 'jotai';

export const DebugPanel = () => {
  const authTokenPayload = useAtomValue(authTokenPayloadAtom);

  return (
    <>
      <div className='mt-8'>
        {authTokenPayload !== undefined ? (
          <>
            <p className='font-bold'>Current auth token contents:</p>
            <pre className='text-sm'>
              {JSON.stringify(authTokenPayload, null, 2)}
            </pre>
          </>
        ) : (
          <p className='font-bold'>No auth token present</p>
        )}
      </div>
    </>
  );
};
