import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { routes } from '~src/routes';
import { ClassNameProps } from '~src/utils/ClassNameProps';
import { Tag } from '../icons/Tag';

export interface TagListProps extends ClassNameProps {
  tagIds: number[];
}

export const TagList = ({ tagIds, className }: TagListProps) => {
  const navigate = useNavigate();
  if (tagIds.length === 0) return <></>;
  return (
    <div
      className={cx(
        'flex flex-row flex-wrap items-start gap-x-4 gap-y-2 my-4 mr-4',
        className
      )}
    >
      {tagIds.map((tagId) => (
        <Tag
          tagId={tagId}
          key={tagId}
          onClick={() => {
            navigate(routes.tagById(tagId));
          }}
        />
      ))}
    </div>
  );
};
