import { mdiClose, mdiContentSave, mdiUpload } from '@mdi/js';
import Icon from '@mdi/react';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  ButtonColor,
  ButtonType,
} from 'src/components/controls/Button';
import { useEditableLocations, useLocationById } from 'src/state/locations';
import { Header, HeaderType } from '~src/components/sections/Header';

export interface EditLocationData {
  name?: string;
  description?: string;
  slug?: string;
  parentLocationId?: number;
  image?: File;
}

export interface EditLocationScreenProps {
  title: string;
  data?: EditLocationData;
  onSubmit: (item: EditLocationData) => void | Promise<void>;
  onCancel: () => void;
}

export const EditLocationScreen = (props: EditLocationScreenProps) => {
  const [image, setImage] = useState<File | undefined>(props.data?.image);

  const editableLocations = useEditableLocations();
  const parentLocation = useLocationById(props.data?.parentLocationId);

  const availableParentLocations = editableLocations.data
    ?.map((loc) => loc.id)
    .includes(parentLocation.data?.id)
    ? editableLocations.data
    : editableLocations.data?.concat(parentLocation.data ?? []);

  const {
    register,
    handleSubmit,
    reset,
    // formState: { errors }, TODO: add error handling
  } = useForm({
    defaultValues: props.data,
  });

  useEffect(() => {
    if (availableParentLocations) {
      reset();
    }
  }, [availableParentLocations?.length]);

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e === null) return;
    if (e.target === null) return;
    if (e.target.files === null) return;
    setImage(e.target.files[0]);
  };

  const submit = async (location: {
    name?: string;
    description?: string;
    slug?: string;
    parentLocationId?: number;
  }) => {
    await props.onSubmit({ ...location, image: image });
  };

  const cancel = (e: React.FormEvent) => {
    e.preventDefault();
    props.onCancel();
  };

  return (
    <div className='my-4'>
      <Header text={props.title} type={HeaderType.SCREEN} className='mb-4' />
      <form onSubmit={handleSubmit(submit)}>
        <div className='pb-2'>
          <label className='block font-semibold' htmlFor='name'>
            Name
          </label>
          <input
            {...register('name', { required: true })}
            className='form-input border-gray-300'
            type='text'
          />
        </div>

        <section className='py-2'>
          <label>
            <p className='font-semibold'>Description</p>
            <textarea className='form-input' {...register('description')} />
          </label>
        </section>

        <div className='py-2'>
          <label htmlFor='slug' className='font-semibold'>
            Slug
          </label>
          <input
            {...register('slug', { required: true })}
            className='form-input border-gray-300'
            type='text'
          />
        </div>

        <div className='py-2'>
          <label htmlFor='parentLocationId' className='font-semibold'>
            Parent location
          </label>
          <select
            {...register('parentLocationId', {
              setValueAs: (value: string) => {
                if (value === 'none') return undefined;
                return value;
              },
            })}
            defaultValue={props.data?.parentLocationId ?? 'none'}
            className='form-input border-gray-300'
          >
            <option key='none' value='none'>
              None
            </option>
            {availableParentLocations?.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </select>
        </div>

        <div className='pt-2 pb-4'>
          <p className='font-semibold'>Image</p>
          <div className='py-2'>
            <label className='block cursor-pointer bg-gray-100 hover:bg-gray-300 text-gray-900 rounded-lg border border-gray-300 overflow-hidden'>
              <img
                className={cx(
                  'w-full object-scale-down block border-gray-300 rounded-t-lg',
                  {
                    'h-16 p-1': image !== undefined,
                    'h-0': image === undefined,
                  }
                )}
                src={image ? URL.createObjectURL(image) : undefined}
                alt='Uploaded location image'
              />
              <p className='mx-auto text-center p-2'>
                <Icon
                  path={mdiUpload}
                  className='w-4 h-4 mr-1 inline-block align-sub'
                />
                {image ? 'Replace image' : 'Upload image'}
              </p>
              <input type='file' onChange={onImageChange} className='sr-only' />
            </label>
          </div>
        </div>

        <div className='py-2'>
          <Button
            color={ButtonColor.PRIMARY_DARK}
            type={ButtonType.DEFAULT}
            icon={mdiContentSave}
            className='mr-2'
          >
            Save
          </Button>
          <Button
            color={ButtonColor.WHITE}
            type={ButtonType.DEFAULT}
            icon={mdiClose}
            onClick={cancel}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};
