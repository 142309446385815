/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TagCreateDTO } from '../models/TagCreateDTO';
import type { TagDTO } from '../models/TagDTO';
import type { TagUpdateDTO } from '../models/TagUpdateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TagControllerService {

    /**
     * @param id
     * @returns TagDTO OK
     * @throws ApiError
     */
    public static findTagById(
        id: number,
    ): CancelablePromise<TagDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tag/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns TagDTO OK
     * @throws ApiError
     */
    public static updateTag(
        id: number,
        requestBody: TagUpdateDTO,
    ): CancelablePromise<TagDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tag/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteTag(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/tag/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TagDTO OK
     * @throws ApiError
     */
    public static allTags(): CancelablePromise<Array<TagDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tag',
        });
    }

    /**
     * @param requestBody
     * @returns TagDTO Created
     * @throws ApiError
     */
    public static saveTag(
        requestBody: TagCreateDTO,
    ): CancelablePromise<TagDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tag',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}