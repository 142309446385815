import { mdiClose, mdiDelete } from '@mdi/js';
import { Button, ButtonColor, ButtonType } from '../controls/Button';
import { CustomDialog } from './CustomDialog';

export interface DeleteDialogProps {
  title: string;
  description: string;
  text: string;
  visible: boolean;
  onClose: () => void | Promise<void>;
  onDelete: () => void | Promise<void>;
}

export const DeleteDialog = (props: DeleteDialogProps) => {
  return (
    <CustomDialog
      visible={props.visible}
      onClose={props.onClose}
      title={props.title}
      description={props.description}
    >
      <p className='my-4'>{props.text}</p>
      <div className='flex flex-row gap-4'>
        <Button
          color={ButtonColor.ERROR}
          type={ButtonType.DEFAULT}
          icon={mdiDelete}
          onClick={() => {
            void props.onClose();
            void props.onDelete();
          }}
          className='flex-grow'
        >
          Delete
        </Button>
        <Button
          color={ButtonColor.WHITE}
          type={ButtonType.DEFAULT}
          icon={mdiClose}
          onClick={props.onClose}
          className='flex-grow'
        >
          Cancel
        </Button>
      </div>
    </CustomDialog>
  );
};
