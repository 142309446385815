import cx from 'classnames';
import { FallbackIcon } from 'src/components/icons/FallbackIcon';
import { LocationDTO } from 'src/generated/api';
import { ClassNameProps } from '~src/utils/ClassNameProps';
import { useImageById } from '~src/state/images';

export interface LocationCardProps extends ClassNameProps {
  location: LocationDTO;
  onClick?: () => void;
}

export const LocationCard = (props: LocationCardProps) => {
  const image = useImageById(props.location.imageId);

  return (
    <article
      className={cx(
        'flex items-center bg-white hover:bg-gray-200 cursor-pointer py-1 border-b border-gray-300 transition-all h-[72px]',
        props.className
      )}
      onClick={props.onClick}
    >
      {
        /** TODO: Temporary override until images are fixed on BE */
        // eslint-disable-next-line no-constant-condition
        false && props.location.imageId ? (
          <img
            src={image?.data}
            className='mx-4 my-2 w-12 h-12 object-cover rounded'
            alt={props.location.name}
          />
        ) : (
          <FallbackIcon text={props.location.name ?? ''} />
        )
      }
      <p className='font-bold'>{props.location.name}</p>
    </article>
  );
};
