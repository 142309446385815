import { mdiPlus } from '@mdi/js';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonColor,
  ButtonType,
} from '~src/components/controls/Button';
import { Loading } from '~src/components/icons/Loading';
import { ErrorMessage } from '~src/components/sections/ErrorMessage';
import { Header, HeaderType } from '~src/components/sections/Header';
import { ItemList } from '~src/components/sections/ItemList';
import { useEditableItems } from '~src/state/items';

export const ManageItemScreen = () => {
  const managedItems = useEditableItems();
  const navigate = useNavigate();

  const getContent = () => {
    if (managedItems.error) {
      return <ErrorMessage />;
    }

    if (managedItems.isLoading) {
      return <Loading />;
    }

    if (managedItems.data) {
      if (managedItems.data.length === 0) {
        return <p>No managed items</p>;
      }

      return <ItemList items={managedItems.data} />;
    }
  };

  return (
    <section className='py-4'>
      <div className='sm:flex items-center my-4'>
        <Header
          text='Managed items'
          type={HeaderType.SECTION}
          className='flex-grow'
        />
        <Button
          color={ButtonColor.PRIMARY_DARK}
          type={ButtonType.DEFAULT}
          icon={mdiPlus}
          onClick={() => navigate('new')}
          className='mt-4 sm:mt-0'
        >
          New item
        </Button>
      </div>
      {getContent()}
    </section>
  );
};
