import {
  mdiAccountWrench,
  mdiCalendarClock,
  mdiViewDashboardOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import cx from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '~src/routes';
import { useUserRoles } from '~src/state/roles';
import { ClassNameProps } from '~src/utils/ClassNameProps';

export enum NavOrientation {
  VERTICAL,
  HORIZONTAL,
}

export interface NavProps extends ClassNameProps {
  orientation: NavOrientation;
  onNavigate?: () => void;
}

export const NavItem = (props: {
  name: string;
  icon: string;
  selected: boolean;
  className: string;
  onClick: () => void;
}) => {
  return (
    <div
      className={cx(
        'px-2 py-1 rounded-lg cursor-pointer transition-all',
        {
          'text-primary-900 bg-primary-300': props.selected,
          'text-gray-900 hover:bg-gray-300': !props.selected,
        },
        props.className
      )}
      onClick={() => {
        props.onClick();
      }}
    >
      <Icon path={props.icon} className='inline-block h-6 w-6 align-middle' />
      <span className='ml-2 align-middle select-none'>{props.name}</span>
    </div>
  );
};

export const Nav = (props: NavProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const userRoles = useUserRoles();

  let currentSection: 'Overview' | 'Manage' | 'Browse';
  if (location.pathname === routes.main) {
    currentSection = 'Overview';
  } else if (
    location.pathname.includes(routes.manage) ||
    location.pathname.includes(routes.role) ||
    location.pathname.includes(routes.user)
  ) {
    currentSection = 'Manage';
  } else {
    currentSection = 'Browse';
  }

  type NavItem = {
    name: typeof currentSection;
    icon: string;
    onClick: () => void;
  };

  const managingNavItems: NavItem[] = [
    {
      name: 'Manage',
      icon: mdiAccountWrench,
      onClick: () => {
        navigate(routes.manage);
        props.onNavigate && props.onNavigate();
      },
    },
  ];

  // TODO: Replace with more robust logic once roles are improved
  const isUserManaging = userRoles.length > 0;

  const navItems: NavItem[] = [
    {
      name: 'Overview',
      icon: mdiViewDashboardOutline,
      onClick: () => {
        navigate(routes.main);
        props.onNavigate && props.onNavigate();
      },
    },
    {
      name: 'Browse',
      icon: mdiCalendarClock,
      onClick: () => {
        navigate(routes.browse);
        props.onNavigate && props.onNavigate();
      },
    },
    ...(isUserManaging ? managingNavItems : []),
  ];

  return (
    <div
      className={cx(
        {
          'flex-row gap-4': props.orientation === NavOrientation.HORIZONTAL,
          'flex-col gap-2': props.orientation === NavOrientation.VERTICAL,
        },
        props.className
      )}
    >
      {navItems.map((item) => (
        <NavItem
          key={item.name}
          name={item.name}
          icon={item.icon}
          selected={currentSection === item.name}
          className={cx({
            'flex-shrink-0': props.orientation === NavOrientation.HORIZONTAL,
          })}
          onClick={item.onClick}
        />
      ))}
    </div>
  );
};
