import { mdiPlus } from '@mdi/js';
import { useNavigate } from 'react-router-dom';
import { TagCard } from '~src/components/cards/TagCard';
import {
  Button,
  ButtonColor,
  ButtonType,
} from '~src/components/controls/Button';
import { Loading } from '~src/components/icons/Loading';
import { Header, HeaderType } from '~src/components/sections/Header';
import { routes } from '~src/routes';
import { useEditableTags } from '~src/state/tags';

export const ManageTagScreen = () => {
  const managedTags = useEditableTags();
  const navigate = useNavigate();

  const getContent = () => {
    if (managedTags.isLoading) {
      return <Loading />;
    }

    if ((managedTags.data?.length ?? 0) === 0) {
      return <p>No managed tags</p>;
    }

    return (
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
        {managedTags?.data?.map((tag) => (
          <TagCard
            key={tag.id}
            tag={tag}
            onClick={() => navigate(routes.tagById(tag.id))}
          />
        ))}
      </div>
    );
  };

  return (
    <section className='py-4'>
      <div className='sm:flex items-center my-4'>
        <Header
          text='Managed tags'
          type={HeaderType.SECTION}
          className='flex-grow'
        />
        <Button
          color={ButtonColor.PRIMARY_DARK}
          type={ButtonType.DEFAULT}
          icon={mdiPlus}
          onClick={() => navigate('new')}
          className='mt-4 sm:mt-0'
        >
          New tag
        </Button>
      </div>
      {getContent()}
    </section>
  );
};
