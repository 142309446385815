import { ReservationList } from 'src/components/sections/ReservationList';
import { Loading } from 'src/components/icons/Loading';
import { Header, HeaderType } from 'src/components/sections/Header';
import { useUnconfirmedReservations } from 'src/state/reservations';
import { ErrorMessage } from '~src/components/sections/ErrorMessage';

export const UnconfirmedList = () => {
  const unconfirmedReservations = useUnconfirmedReservations();

  let content;
  if (unconfirmedReservations.isLoading) {
    content = <Loading />;
  } else if (unconfirmedReservations.isError) {
    content = <ErrorMessage />;
  } else if (unconfirmedReservations.data) {
    content = (
      <ReservationList
        reservations={unconfirmedReservations.data}
        showReservee={true}
        sorted
      />
    );
  }

  return (
    <section className='my-8'>
      {unconfirmedReservations?.data?.length === 0 ? (
        <Header
          text='There are no reservations waiting for confirmation'
          type={HeaderType.SUBSECTION}
        />
      ) : (
        <Header
          text='Waiting for confirmation'
          type={HeaderType.SECTION}
          count={unconfirmedReservations?.data?.length}
          className='pb-4'
        />
      )}
      {content}
    </section>
  );
};
