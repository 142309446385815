import { useNavigate } from 'react-router-dom';
import { ReservationCard } from 'src/components/cards/ReservationCard';
import {
  ReservationDetail,
  useReservationActions,
} from 'src/state/reservations';
import { showToast } from 'src/utils/toast';
import { routes } from '~src/routes';

export const ReservationList = ({
  reservations,
  sorted = false,
  showReservee = true,
  noImage = false,
}: {
  reservations: ReservationDetail[];
  sorted: boolean;
  showReservee: boolean;
  noImage?: boolean;
}) => {
  const reservationActions = useReservationActions();
  const navigate = useNavigate();

  const sortReservations = (
    a: ReservationDetail,
    b: ReservationDetail
  ): number => {
    if (
      a.reservation.startTime === undefined ||
      b.reservation.startTime === undefined
    ) {
      return 0;
    }
    const timestampA = Date.parse(a.reservation.startTime);
    const timestampB = Date.parse(b.reservation.startTime);
    return timestampA - timestampB;
  };

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 items-stretch'>
      {(sorted ? reservations.sort(sortReservations) : reservations).map(
        (res) => (
          <ReservationCard
            key={res.reservation.id}
            reservation={res.reservation}
            locations={res.locations}
            item={res.item}
            noImage={noImage}
            reserveeName={
              showReservee
                ? `${res.reservation.firstName ?? ''} ${
                    res.reservation.lastName ?? ''
                  }`
                : undefined
            }
            onItemNameClick={() => navigate(routes.itemBySlug(res.item.slug))}
            onConfirm={async () => {
              if (res.reservation) {
                await reservationActions.confirm.mutateAsync(res.reservation);
              }
              showToast.success('Reservation confirmed successfully.');
            }}
            onCancel={async () => {
              if (res.reservation.id) {
                await reservationActions.cancel.mutateAsync(res.reservation);
              }
              showToast.success('Reservation cancelled successfully.');
            }}
            onDeny={async () => {
              if (res.reservation) {
                await reservationActions.deny.mutateAsync(res.reservation);
              }
              showToast.success('Reservation denied successfully.');
            }}
          />
        )
      )}
    </div>
  );
};
