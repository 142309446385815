import { LocationDTO } from '~src/generated/api';

export const sortLocations = (a: LocationDTO, b: LocationDTO) => {
  const nameA = a.name?.toUpperCase();
  const nameB = b.name?.toUpperCase();
  if (nameA === undefined) {
    return 1;
  }
  if (nameB === undefined) {
    return -1;
  }

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};
