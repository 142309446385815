import { useState } from 'react';

export const useStateList = <Type,>(
  initialValue: Type[],
  removeFilterFn: (id: number, item: Type) => boolean
) => {
  const [state, setState] = useState<Type[]>(initialValue);
  const add = (value: Type) => {
    setState([...state, value]);
  };
  const remove = (id: number) => {
    setState(state.filter((item: Type) => !removeFilterFn(id, item)));
  };
  return { state, add, remove };
};
