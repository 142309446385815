import { mdiClose, mdiContentSave, mdiPlus } from '@mdi/js';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ItemEditCard } from '~src/components/cards/ItemEditCard';
import { LocationEditCard } from '~src/components/cards/LocationEditCard';
import { RoleEditCard } from '~src/components/cards/RoleEditCard';
import {
  Button,
  ButtonColor,
  ButtonType,
} from '~src/components/controls/Button';
import { Chip } from '~src/components/icons/Chip';
import { Header, HeaderType } from '~src/components/sections/Header';
import { PickDialog } from '~src/components/sections/PickDialog';
import { ItemDTO, LocationDTO, RoleDTO, TagDTO } from '~src/generated/api';
import { useEditableItems } from '~src/state/items';
import { useEditableLocations } from '~src/state/locations';
import { useEditableRoles } from '~src/state/roles';
import { useEditableTags } from '~src/state/tags';
import { useStateList } from '~src/utils/useStateList';

export interface EditRoleData {
  name?: string;
  isAdmin?: boolean;
  roleItems: ItemDTO[];
  roleLocations: LocationDTO[];
  roleTags: TagDTO[];
  roleRoles: RoleDTO[];
}

export interface EditRoleScreenProps {
  title: string;
  roleId?: number;
  data?: EditRoleData;
  onSubmit: (role: EditRoleData) => void | Promise<void>;
  onCancel: () => void;
}

export const EditRoleScreen = ({
  title,
  roleId,
  data,
  onSubmit,
  onCancel,
}: EditRoleScreenProps) => {
  const roleItems = useStateList<ItemDTO>(
    data?.roleItems ?? [],
    (id, item) => item.id === id
  );
  const roleLocations = useStateList(
    data?.roleLocations ?? [],
    (id, location) => location.id === id
  );
  const roleTags = useStateList(
    data?.roleTags ?? [],
    (id, tag) => tag.id === id
  );
  const roleRoles = useStateList(
    data?.roleRoles ?? [],
    (id, role) => role.id === id
  );

  enum VisibleDialogType {
    ADD_ITEM,
    ADD_LOCATION,
    ADD_TAG,
    ADD_ROLE,
    NONE,
  }
  const [visibleDialog, setVisibleDialog] = useState(VisibleDialogType.NONE);

  const [isAdmin, setAdmin] = useState(data?.isAdmin);

  const managedItems = useEditableItems();
  const managedLocations = useEditableLocations();
  const managedTags = useEditableTags();
  const managedRoles = useEditableRoles();

  const {
    register,
    handleSubmit,
    // formState: { errors }, TODO: add error handling
  } = useForm({
    defaultValues: {
      ...data,
    },
  });

  const submit = async (role: { name?: string }) => {
    await onSubmit({
      ...role,
      isAdmin,
      roleItems: roleItems.state,
      roleLocations: roleLocations.state,
      roleRoles: roleRoles.state,
      roleTags: roleTags.state,
    });
  };

  const cancel = (e: React.FormEvent) => {
    e.preventDefault();
    onCancel();
  };

  return (
    <>
      <>
        <PickDialog
          title='Add item'
          description='Choose an item to add'
          options={
            managedItems.data
              ?.filter(
                (item) =>
                  !roleItems.state.map((item) => item.id).includes(item.id)
              )
              ?.map((item) => ({
                name: item.name ?? 'Unknown item',
                value: item,
              })) ?? []
          }
          visible={visibleDialog === VisibleDialogType.ADD_ITEM}
          onClose={() => setVisibleDialog(VisibleDialogType.NONE)}
          onPick={(item?: ItemDTO) => item && roleItems.add(item)}
        />
        <PickDialog
          title='Add location'
          description='Choose a location to add'
          options={
            managedLocations.data
              ?.filter(
                (location) =>
                  !roleLocations.state
                    .map((loc) => loc.id)
                    .includes(location.id)
              )
              ?.map((location) => ({
                name: location.name ?? 'Unknown location',
                value: location,
              })) ?? []
          }
          visible={visibleDialog === VisibleDialogType.ADD_LOCATION}
          onClose={() => setVisibleDialog(VisibleDialogType.NONE)}
          onPick={(location?: LocationDTO) =>
            location && roleLocations.add(location)
          }
        />
        <PickDialog
          title='Add tag'
          description='Choose a tag to add'
          options={
            managedTags.data
              ?.filter(
                (tag) =>
                  !roleTags.state.map((roleTag) => roleTag.id).includes(tag.id)
              )
              ?.map((tag) => ({
                name: tag.name ?? 'Unknown tag',
                value: tag,
              })) ?? []
          }
          visible={visibleDialog === VisibleDialogType.ADD_TAG}
          onClose={() => setVisibleDialog(VisibleDialogType.NONE)}
          onPick={(tag?: TagDTO) => tag && roleTags.add(tag)}
        />
        <PickDialog
          title='Add role'
          description='Choose a role to add'
          options={
            managedRoles.data
              ?.filter(
                (role) =>
                  !roleRoles.state.map((role) => role.id).includes(role.id) &&
                  (roleId === undefined || role.id !== roleId)
              )
              ?.map((role) => ({
                name: role.name ?? 'Unknown role',
                value: role,
              })) ?? []
          }
          visible={visibleDialog === VisibleDialogType.ADD_ROLE}
          onClose={() => setVisibleDialog(VisibleDialogType.NONE)}
          onPick={(role?: RoleDTO) => role && roleRoles.add(role)}
        />
      </>

      <div className='my-4'>
        <Header text={title} type={HeaderType.SCREEN} className='mb-4' />
        <form onSubmit={handleSubmit(submit)}>
          <div className='pb-2'>
            <label className='block font-semibold' htmlFor='name'>
              Name
            </label>
            <input
              {...register('name', { required: true })}
              className='form-input border-gray-300'
              type='text'
            />
          </div>

          <div className='py-2'>
            <label className='inline-flex items-center w-full cursor-pointer'>
              <input
                {...register('isAdmin', {
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    setAdmin(e.target.checked),
                })}
                className='peer sr-only'
                type='checkbox'
              />
              <div className='mr-4 flex-grow inline-block'>
                <p className='font-semibold'>
                  {isAdmin ? 'Unlimited access' : 'Limited access'}
                </p>
                <p className='text-sm text-gray-700'>
                  This setting indicates whether users with this role have full
                  access to the system.
                  <span className='text-warning-700 ml-1'>
                    Use with caution.
                  </span>
                </p>
              </div>
              <div className='flex-shrink-0 form-checkbox-toggle' />
            </label>
          </div>

          <section className='py-4'>
            <div className='sm:flex items-center'>
              <Header
                text='Managed items'
                type={HeaderType.SECTION}
                className='flex-grow'
              />

              <Button
                icon={mdiPlus}
                color={ButtonColor.PRIMARY_LIGHT}
                type={ButtonType.DEFAULT}
                onClick={(e) => {
                  e.preventDefault();
                  setVisibleDialog(VisibleDialogType.ADD_ITEM);
                }}
                className='mt-2 sm:mt-0 flex-shrink-0'
              >
                Add item
              </Button>
            </div>
            {(roleItems.state?.length ?? 0) === 0 ? (
              <p className='text-gray-700 my-2'>No items</p>
            ) : (
              <div className='my-4 grid grid-cols-1 sm:grid-cols-2 gap-4'>
                {roleItems.state?.map((item) => (
                  <ItemEditCard
                    key={item.id}
                    item={item}
                    onDelete={() => item.id && roleItems.remove(item.id)}
                  />
                ))}
              </div>
            )}
          </section>

          <section className='pb-4'>
            <div className='sm:flex items-center'>
              <Header
                text='Managed locations'
                type={HeaderType.SECTION}
                className='flex-grow'
              />

              <Button
                icon={mdiPlus}
                color={ButtonColor.PRIMARY_LIGHT}
                type={ButtonType.DEFAULT}
                onClick={(e) => {
                  e.preventDefault();
                  setVisibleDialog(VisibleDialogType.ADD_LOCATION);
                }}
                className='mt-2 sm:mt-0 flex-shrink-0'
              >
                Add location
              </Button>
            </div>
            {(roleLocations.state?.length ?? 0) === 0 ? (
              <p className='text-gray-700 my-2'>No locations</p>
            ) : (
              <div className='my-4'>
                {roleLocations.state?.map((location) => (
                  <LocationEditCard
                    key={location.id}
                    location={location}
                    onDelete={() =>
                      location.id && roleLocations.remove(location.id)
                    }
                  />
                ))}
              </div>
            )}
          </section>

          <section className='pb-4'>
            <div className='sm:flex'>
              <Header
                text='Managed tags'
                type={HeaderType.SECTION}
                className='flex-grow'
              />
              <Button
                color={ButtonColor.PRIMARY_LIGHT}
                type={ButtonType.DEFAULT}
                icon={mdiPlus}
                onClick={(e) => {
                  e.preventDefault();
                  setVisibleDialog(VisibleDialogType.ADD_TAG);
                }}
                className='mt-4 sm:mt-0'
              >
                Add tag
              </Button>
            </div>
            {roleTags.state.length === 0 ? (
              <p className='text-gray-700 my-2'>No tags</p>
            ) : (
              <div className='flex flex-row flex-wrap items-start gap-x-4 gap-y-2 my-2'>
                {roleTags.state.map((tag) => (
                  <Chip
                    key={tag.id}
                    text={tag.name}
                    onDelete={() => tag.id && roleTags.remove(tag.id)}
                  />
                ))}
              </div>
            )}
          </section>

          <section className='pb-4'>
            <div className='sm:flex'>
              <Header
                text='Managed roles'
                type={HeaderType.SECTION}
                className='flex-grow'
              />
              <Button
                color={ButtonColor.PRIMARY_LIGHT}
                type={ButtonType.DEFAULT}
                icon={mdiPlus}
                onClick={(e) => {
                  e.preventDefault();
                  setVisibleDialog(VisibleDialogType.ADD_ROLE);
                }}
                className='mt-4 sm:mt-0'
              >
                Add role
              </Button>
            </div>
            {roleRoles.state.length === 0 ? (
              <p className='text-gray-700 my-2'>No roles</p>
            ) : (
              <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 my-4'>
                {roleRoles.state?.map((role) => (
                  <RoleEditCard
                    key={role.id}
                    role={role}
                    onDelete={() => role.id && roleRoles.remove(role.id)}
                  />
                ))}
              </div>
            )}
          </section>

          <section className='py-4'>
            <Button
              color={ButtonColor.PRIMARY_DARK}
              type={ButtonType.DEFAULT}
              icon={mdiContentSave}
              className='mr-2'
            >
              Save
            </Button>
            <Button
              color={ButtonColor.WHITE}
              type={ButtonType.DEFAULT}
              icon={mdiClose}
              onClick={cancel}
            >
              Cancel
            </Button>
          </section>
        </form>
      </div>
    </>
  );
};
