/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReservationDTO = {
    id?: number;
    startTime?: string;
    endTime?: string;
    state?: ReservationDTO.state;
    userId?: number;
    firstName?: string;
    lastName?: string;
    itemId?: number;
};

export namespace ReservationDTO {

    export enum state {
        CREATED = 'CREATED',
        CONFIRMED = 'CONFIRMED',
        DENIED = 'DENIED',
        CANCELLED = 'CANCELLED',
        COMPLETED = 'COMPLETED',
    }


}
