import { useNavigate } from 'react-router-dom';
import { useCreateItem } from 'src/state/items';
import { showToast } from 'src/utils/toast';
import { routes } from '~src/routes';
import { EditItemData, EditItemScreen } from './EditItemScreen';

export const NewItemScreen = () => {
  const navigate = useNavigate();

  const createItem = useCreateItem();

  const addItem = (item: EditItemData) => {
    const tagIds = item.tags?.flatMap((tag) => tag.id ?? []);
    const image = item.image;
    delete item.tags;
    delete item.image;
    createItem
      .mutateAsync({
        item: { ...item, tagIds },
        image,
      })
      .then((item) => {
        showToast.success('Item added successfully');
        navigate(routes.itemBySlug(item.slug));
      })
      .catch(() => {
        showToast.error('Could not add item');
      });
  };

  return (
    <EditItemScreen
      title='New item'
      onSubmit={addItem}
      onCancel={() => navigate(-1) /* go back */}
    />
  );
};
