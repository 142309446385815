import { mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';

export const Loading = () => {
  return (
    <div>
      <Icon path={mdiLoading} spin className='h-4 w-4 inline-block mr-2' />
      <span className='inline-block'>Loading...</span>
    </div>
  );
};
