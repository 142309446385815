import cx from 'classnames';
import { ClassNameProps } from '~src/utils/ClassNameProps';
import { Indicator, IndicatorProps } from './Indicator';

export interface ItemIndicatorProps extends ClassNameProps {
  availability: number;
}

export const ItemIndicator = (props: ItemIndicatorProps) => {
  const indicatorPropsFromAvailability = (
    availability: number
  ): IndicatorProps => {
    if (availability === 0)
      return {
        className: 'text-error-900',
        filled: false,
        text: 'Not available',
      };

    if (availability < 40)
      return {
        className: 'text-error-700',
        filled: true,
        text: 'Low availability',
      };

    if (availability < 80)
      return {
        className: 'text-warning-700',
        filled: true,
        text: 'Medium availability',
      };

    return {
      className: 'text-primary-700',
      filled: true,
      text: 'High availability',
    };
  };

  const indicatorProps = indicatorPropsFromAvailability(props.availability);

  return (
    <Indicator
      {...indicatorProps}
      className={cx(indicatorProps.className, props.className)}
    />
  );
};
