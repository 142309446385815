import {
  mdiAccountCancel,
  mdiAccountCheck,
  mdiAt,
  mdiCancel,
  mdiCardAccountDetailsOutline,
  mdiDelete,
  mdiInformationOutline,
  mdiPencil,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RoleCard } from '~src/components/cards/RoleCard';
import {
  Button,
  ButtonColor,
  ButtonType,
} from '~src/components/controls/Button';
import { Loading } from '~src/components/icons/Loading';
import { DeleteDialog } from '~src/components/sections/DeleteDialog';
import { Header, HeaderType } from '~src/components/sections/Header';
import { NotFoundMessage } from '~src/components/sections/NotFoundMessage';
import { routes } from '~src/routes';
import { useRolesForUser } from '~src/state/roles';
import { useDeleteUser, useUpdateUser, useUserById } from '~src/state/user';
import { showToast } from '~src/utils/toast';
import { BanDialog } from './components/BanDialog';
import { EditUserData, EditUserScreen } from './EditUserScreen';

export const UserScreen = () => {
  const urlParams = useParams();
  const navigate = useNavigate();

  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [isBanOpen, setBanOpen] = useState<boolean>(false);
  const [isEditVisible, setEditVisible] = useState<boolean>(false);

  const userId = Number(urlParams.userId);
  const { error, isLoading, data: user } = useUserById(userId);

  const updateUser = useUpdateUser();
  const deleteUser = useDeleteUser();

  const roles = useRolesForUser(userId);

  if (urlParams.userId === undefined || isNaN(userId)) {
    return <NotFoundMessage />;
  }

  const getContent = () => {
    if (user) {
      return (
        <>
          <div className='my-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
            <div>
              <p>
                <Icon
                  path={mdiCardAccountDetailsOutline}
                  size='1em'
                  className='inline-block mr-1 align-sub'
                />
                <strong className='font-semibold'>Username</strong>
              </p>
              <p>{user.username}</p>
            </div>

            <div>
              <p>
                <Icon
                  path={mdiAt}
                  size='1em'
                  className='inline-block mr-1 align-sub'
                />
                <span className='font-semibold'>Email</span>
              </p>
              <p>{user.email}</p>
            </div>

            {user.uid && (
              <div>
                <p>
                  <Icon
                    path={mdiInformationOutline}
                    size='1em'
                    className='inline-block mr-1 align-sub'
                  />
                  <strong className='font-semibold'>IS UID</strong>
                </p>
                <p>
                  <a
                    className='hover:text-gray-700'
                    href={`https://is.sh.cvut.cz/users/${user.uid}`}
                  >
                    {user.uid}
                  </a>
                </p>
              </div>
            )}

            <p className='font-semibold'>
              <Icon
                path={user.active ? mdiAccountCheck : mdiAccountCancel}
                size='1em'
                className='inline-block mr-1 align-sub'
              />
              {user.active ? 'Active' : 'Disabled'}
            </p>
          </div>
          <div>
            <Header text='Roles' type={HeaderType.SECTION} />
            {roles.length === 0 ? (
              <p className='my-2 text-gray-700'>No roles</p>
            ) : (
              <div className='my-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3'>
                {roles
                  .flatMap((role) => role.data ?? [])
                  .map((role) => (
                    <RoleCard
                      key={role.id}
                      role={role}
                      onClick={() => navigate(routes.roleById(role.id))}
                    />
                  ))}
              </div>
            )}
          </div>
        </>
      );
    }

    if (error) {
      return <p>An error occurred</p>;
    }

    if (isLoading) {
      return <Loading />;
    }
  };

  if (isEditVisible) {
    return (
      <EditUserScreen
        title='Edit user'
        data={{ ...user, roles: roles.flatMap((role) => role.data ?? []) }}
        onSubmit={(newUser: EditUserData) => {
          const roleIds = newUser.roles?.flatMap((role) => role.id ?? []) ?? [];
          delete newUser.roles;

          updateUser
            .mutateAsync({ userId, user: { ...newUser, roleIds } })
            .then(() => {
              showToast.success('User updated successfully');
              setEditVisible(false);
            })
            .catch(() => {
              showToast.error('Could not update user');
            });
        }}
        onCancel={() => setEditVisible(false)}
      />
    );
  }

  return (
    <>
      <DeleteDialog
        title='Delete user'
        description='This will delete the user!'
        text='Are you sure you want to delete this user?'
        visible={isDeleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDelete={() => {
          deleteUser
            .mutateAsync(userId)
            .then(() => {
              showToast.success('User deleted successfully');
              navigate(routes.browse);
            })
            .catch(() => {
              showToast.error('Could not delete user');
            });
        }}
      />
      <BanDialog
        userId={userId}
        visible={isBanOpen}
        onClose={() => setBanOpen(false)}
      />
      <div className='my-4'>
        <section className='my-4'>
          <Header
            text={`${user?.firstName ?? ''} ${user?.lastName ?? ''}` ?? 'User'}
            type={HeaderType.SCREEN}
          />
        </section>
        {user !== undefined && (
          <section className='flex flex-wrap flex-row gap-x-4 gap-y-2 my-4'>
            <Button
              icon={mdiPencil}
              color={ButtonColor.WHITE}
              type={ButtonType.DEFAULT}
              onClick={() => {
                setEditVisible(true);
              }}
            >
              Edit
            </Button>
            <Button
              icon={mdiCancel}
              color={ButtonColor.ERROR}
              type={ButtonType.DEFAULT}
              onClick={() => {
                setBanOpen(true);
              }}
            >
              Ban
            </Button>
            <Button
              icon={mdiDelete}
              color={ButtonColor.ERROR}
              type={ButtonType.DEFAULT}
              onClick={() => {
                setDeleteOpen(true);
              }}
            >
              Delete
            </Button>
          </section>
        )}
        <section className='my-4'>{getContent()}</section>
      </div>
    </>
  );
};
