import { atom } from 'jotai';

export enum Environment {
  PRODUCTION,
  DEVELOPMENT,
}

export const environmentAtom = atom(() => {
  if (process.env.PROD_TEST === 'true') return Environment.PRODUCTION;
  if (process.env.NODE_ENV === 'production') return Environment.PRODUCTION;
  return Environment.DEVELOPMENT;
});
