import { useTagById } from '~src/state/tags';
import { Chip } from './Chip';

export const Tag = ({
  tagId,
  onClick,
  onDelete,
}: {
  tagId: number;
  onClick?: () => void | Promise<void>;
  onDelete?: () => void | Promise<void>;
}) => {
  const tag = useTagById(tagId);
  if (tag.data === undefined) return <></>;
  return <Chip text={tag.data.name} onClick={onClick} onDelete={onDelete} />;
};
