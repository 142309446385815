import { useNavigate } from 'react-router-dom';
import { LocationCard } from 'src/components/cards/LocationCard';
import { Loading } from 'src/components/icons/Loading';
import { routes } from 'src/routes';
import { useRootLocations } from 'src/state/locations';
import { sortLocations } from '~src/utils/sorting';
import { ErrorMessage } from './ErrorMessage';

export const LocationList = () => {
  const rootLocations = useRootLocations();
  const navigate = useNavigate();

  let content;
  if (rootLocations.isLoading) {
    content = <Loading />;
  } else if (rootLocations.isError) {
    content = <ErrorMessage />;
  } else if (rootLocations.data) {
    content = (
      <div className='rounded-lg bg-white shadow-soft overflow-clip'>
        {rootLocations.data.sort(sortLocations).map((location) => (
          <LocationCard
            key={location.id}
            location={location}
            onClick={() => navigate(routes.locationBySlug(location.slug))}
          />
        ))}
      </div>
    );
  }

  return <section>{content}</section>;
};
